<template>
	<v-layout justify-center class="login-page">
		<v-flex xs12 sm8 md4 class="wrapper">
			<img class="app-logo" :src="displayedLogoUrl">

			<v-card>
				<v-card-text class="card-content" v-if="!loading">
					<v-form id="loginForm">
						<v-text-field
							label="Username"
							type="text"
							v-model="email"
							outlined
							:error-messages="emailErrors"
							@keyup.enter.native="signIn"
							ref="email"
							id="username"
						></v-text-field>

						<v-text-field
							label="Password"
							type="password"
							v-model="password"
							outlined
							:error-messages="passwordErrors"
							@keyup.enter.native="signIn"
							@keydown.native="checkCapsLock"
							ref="password"
							id="password"
						></v-text-field>
					</v-form>

					<v-btn color="primary" @click="signIn" id="loginButton" rounded>Log In</v-btn>

					<div v-if="capsLockMessage" class="error-message">{{ capsLockMessage }}</div>
					<div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
				</v-card-text>

				<v-progress-circular class="loading" indeterminate v-if="loading"></v-progress-circular>
			</v-card>

			<v-btn text to="/reset-password" class="forgot-password-button">Forgot Password</v-btn>

			<v-alert
				type="warning"
				:value="!!redirectWarningMessage"
				transition="scale-transition"
				class="bottom-message">

				{{ redirectWarningMessage }}
			</v-alert>
		</v-flex>
	</v-layout>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { client } from '../utils/axios';

export default {
	props: {
		'emailOnly': {
			default: true
		}
	},
	name: 'LandingPage',
	data() {
		return {
			email: '',
			password: '',
			loading: false,
			errorMessage: null,
			capsLockMessage: null
		};
	},
	validations() {
		const emailValidations = { required };
		if(this.emailOnly) {
			emailValidations.email = email;
		}

		return {
			email: emailValidations,
			password: { required }
		};
	},
	computed: {
		emailErrors() {
			const errors = [];
			if(this.$v.email.$dirty) {
				this.$v.email.required || errors.push('Username is required');
				if(this.emailOnly) {
					this.$v.email.email || errors.push('Username is required to be a valid email');
				}
			}

			return errors;
		},
		passwordErrors() {
			const errors = [];
			if(this.$v.password.$dirty) {
				this.$v.password.required || errors.push('Password is required');
			}

			return errors;
		},
		redirectWarningMessage() {
			return this.$route?.query?.warningMessage ?? null;
		},

		displayedLogoUrl() {
			return this.lightLargeLogoUrl;
		}
	},
	methods: {
		checkCapsLock(e) {
			// Not actually typing
			if(!e.key) {
				return;
			}

			let isCapsLockOn = e.getModifierState && e.getModifierState('CapsLock');
			if(isCapsLockOn) {
				this.capsLockMessage = 'You are typing your password with the caps lock on!';
			} else {
				this.capsLockMessage = null;
			}
		},
		signIn() {
			this.errorMessage = null;
			this.$v.$touch();

			let error = this.$v.$error;
			if(!error) {
				this.loading = true;
				client.post('/ajax/login.php', {
					username: this.email,
					password: this.password,
					rememberMe: true
				}).then((data) => {
					if(this.$route?.query?.redirect) {
						let redirect = this.$route.query.redirect;
						if(redirect[0] === '/') {
							redirect = redirect.substr(1);
						}
						window.location = '/' + redirect;
					} else if(data.url) {
						window.location = '/' + data.url;
					} else {
						window.location = '/';
					}
				}).catch((error) => {
					this.loading = false;

					this.errorMessage = 'The username/password combination you entered is incorrect.';
					try {
						if(error.response && error.response.data && error.response.data.reason) {
							let reason = error.response.data.reason;
							if(reason != 'Plic error' && reason != 'Authentication error' && reason != 'Error from PLIC') {
								this.errorMessage = reason;
							}
						}
					} catch(e) {
						// eslint-disable-next-line
						console.error('Failed to login: ', e, error);
					}
				});
			}
		}
	},
	mounted() {
		this.$refs.email.$el.querySelector('input').focus();

		if(this.$route?.query?.username) {
			this.email = this.$route.query.username;
		}
	}
}
</script>

<style scoped>
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.app-logo {
	width: 50%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2em;
}

.bottom-message {
	margin-top: 1em;
}

.loading {
	min-height: 8em;
}

.policies {
	margin-top: 1em;
	display: block;
	display: flex;
	align-items: center;
	justify-content: center;
}

.forgot-password-button {
	font-size: 16px;
	color: var(--color-zinc-500);
}

#loginButton {
	width: 100%;
}

.card-content {
	padding: 24px;
}

.forgot-password-button {
	margin-top: 2em;
}

.error-message {
	margin-top: 1em;
	color: var(--v-error-base);
}
</style>