import Vue from 'vue';
import Router from 'vue-router';
import LandingPage from './landing/LandingPage';
import LoginPage from '../PlicCanvas/vue/login/LoginPage.vue';
import SubjectUploaderPage from './subject-uploader/UploaderPage';
import ResetPasswordPage from '../PlicCanvas/vue/login/ResetPasswordPage.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	fallback: false,
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/guest',
			name: 'guest',
			component: LandingPage
		},
		{
			path: '/guest/login',
			name: 'login',
			component: LoginPage,
			alias: ['/login', '/Login.php']
		},
		{
			path: '/guest/reset-password',
			name: 'reset-password',
			component: ResetPasswordPage,
			alias: ['/reset-password']
		},
		{
			path: '/guest/subject-uploader/:id',
			name: 'subject-uploader',
			component: SubjectUploaderPage
		}
	]
})
