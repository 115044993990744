<template>
	<v-stepper v-model="currentStep" class="uploader" @drop.prevent @dragover.prevent>
		<div class="upload-header">
			<div class="main-header header text-h5" style="color: initial;" v-if="title">
				{{ title }}
			</div>
			<div class="sub-header header sub-headline" v-if="title">
				{{ subtitle }}
			</div>
		</div>

		<v-stepper-header class="uploader-header">
			<template v-for="(step, index) in steps">

				<v-stepper-step :key="step.component.name + '-step'"
					:complete="step > index"
					:step="index + 1">
					
						{{ step.title }}
						<small v-if="step.subtitle">{{ step.subtitle }}</small>
					</v-stepper-step>
				<v-divider :key="step.component.name + '-divider'" v-if="index != steps.length - 1"></v-divider>
			</template>
		</v-stepper-header>

		<v-stepper-items>
			<v-stepper-content v-for="(step, index) in steps" :key="step.component.name" :step="index + 1">
				<component
					:is="step.component"
					v-on:next-step="nextStep"
					v-on:prev-step="prevStep"
					v-on:start-over="startOver"
					v-on:set-files="setFiles"
					v-on:update-custom-tags="updateCustomTags"
					v-on:upload-finished="uploadFinished"
					v-on:stop="stop"
					v-on:all-uploads-finished="allUploadsFinished"
					:last-step-event="lastStepEvent"
					:is-visible="(index + 1) == currentStep"
					:uploads="uploads"
					:image-requirements="imageRequirements"
					:album-id="albumId"
					:choose-file-message="chooseFileMessageComputed"
					:complete-message="completeMessage"
					:max-concurrent-uploads="maxConcurrentUploads"
					:class="{ 'active-step': ((index + 1) == currentStep) }"
					:complete-button="completeButton"
					:tags="tags"
					:tag-label="tagLabel"
					:photo-category="photoCategory"
					:allow-multiple="allowMultiple"
					:tag-picker-component="tagPickerComponent"
					:choose-file-header-component="chooseFileHeaderComponent"
					:accepted-file-extensions="combinedAcceptedFileExtensions"
					:photo-converters="combinedPhotoConverters"
					:project-id="projectId"
					:stop-button="stopButton"
					:select-files-button="selectFilesButton"
					:select-folder-button="selectFolderButton"
					:allow-file-uploads="allowFileUploads"
					:allow-folder-uploads="allowFolderUploads"
					:project="project"
					:create-import-event="createImportEvent"
					:finish-import-event="finishImportEvent"
					:project-import-id="projectImportId"
					:instagram-use-popup="instagramUsePopup"
					:initial-drop-event="initialDropEvent"
					:check-valid-uploader-function="checkValidUploaderFunction"
					:extra-step-props="extraStepProps"
					:is-valid-image="isValidImage"
					:upload-photo-requirements="uploadPhotoRequirements"
					ref="active-component" />
			</v-stepper-content>
		</v-stepper-items>
	</v-stepper>
</template>

<script>
import ChooseFiles from './ChooseFiles';
import ConfirmUpload from './ConfirmUpload';
import UploadProgress from './UploadProgress';
import UploadComplete from './UploadComplete';
import TagPicker from './TagPicker';
import { plicAxios, shouldRetryAll } from '../utils/axios';
import createUpload from './utils/create-upload';
import getDefaultFileExtensions from './utils/get-default-file-extensions';

import pdfjsLib from 'pdfjs-dist';
// NOTE: This is compiled in compileAssets.php from webpack source
pdfjsLib.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.js';

export default {
	name: 'PlicUploader',
	props: {
		title: null,
		subtitle: null,
		albumId: null,
		completeMessage: null,
		chooseFileComponent: {
			default() {
				return ChooseFiles;
			}
		},
		chooseFileStepDescription: {
			default() {
				return window.i18n.t('uploader.chooseFileStepDescription');
			}
		},
		chooseFileMessage: null,
		chooseFileHeaderComponent: null,
		maxConcurrentUploads: {
			default: () => 5
		},
		completeButton: {
			default() {
				return {
					text: window.i18n.t('uploader.completeButton'),
					action: () => {
						this.startOver();
					}
				};
			}
		},
		embeddedTags: null,
		tagLabel: {
			default: () => window.i18n.t('uploader.tags')
		},
		photoCategory: null,
		allowMultiple: {
			default: () => true
		},
		tagPickerComponent: {
			default: () => TagPicker
		},
		acceptedFileExtensions: {
			default() {
				return [];
			}
		},
		photoConverters: {
			default() {
				return {};
			}
		},
		projectId: null,
		stopButton: null,
		selectFilesButton: null,
		selectFolderButton: {
			default() {
				return window.i18n.t('uploader.uploadFolder');
			}
		},
		allowFileUploads: {
			default() {
				return true;
			}
		},
		allowFolderUploads: {
			default() {
				return true;
			}
		},
		project: null,
		confirmUploadComponent: {
			default() {
				return ConfirmUpload;
			}
		},
		confirmUploadStepDescription: {
			default() {
				return window.i18n.t('uploader.confirmUploadStepDescription');
			}
		},
		uploadProgressStepDescription: {
			default() {
				return window.i18n.t('uploader.uploadProgressStepDescription');
			}
		},
		createImportEvent: {
			default() {
				return function() {
					return new Promise((resolve, reject) => {
						plicAxios.post(`albums/${this.albumId}/album-imports.json`, null, {
							raxConfig: {
								shouldRetry: shouldRetryAll
							}
						}).then((response) => {
							if(!response.data.album_import) {
								console.error('response without album_import: ', response.data);
							}
							resolve(response.data.album_import.id);
						}).catch((error) => {
							reject(error);
						});
					});
				};
			}
		},
		finishImportEvent: {
			default() {
				return function() {
					return plicAxios.patch(`album-imports/${this.albumImportId}/finish.json`);
				};
			}
		},
		projectImportId: null,
		instagramUsePopup: {
			default() {
				return true;
			}
		},
		initialDropEvent: null,
		checkValidUploaderFunction: null,
		extraStepProps: {
			default() {
				return {};
			}
		},
		uploadCompleteComponent: {
			default() {
				return UploadComplete;
			}
		},
		isValidImage: null,
		uploadPhotoRequirements: null
	},
	data() {
		let { acceptedFileExtensions, photoConverters } =  getDefaultFileExtensions();

		return {
			currentStep: 1,
			lastStepEvent: null,
			uploads: [],
			imageRequirements: {
				validTypes: ['image/jpeg', 'image/png'],
				maxSize: 26214400
			},
			customTags: [],
			defaultAcceptedFileExtensions: acceptedFileExtensions,
			defaultPhotoConverters: photoConverters
		};
	},
	computed: {
		steps() {
			let steps = [
				{
					title: this.i18n('uploader.click'),
					subtitle: this.chooseFileStepDescription,
					component: this.chooseFileComponent
				},
				{
					title: this.i18n('uploader.choose'),
					subtitle: this.confirmUploadStepDescription,
					component: this.confirmUploadComponent
				},
				{
					title: this.i18n('uploader.wait'),
					subtitle: this.uploadProgressStepDescription,
					component: UploadProgress
				},
				{
					title: this.i18n('uploader.done'),
					component: this.uploadCompleteComponent
				}
			];

			return steps;
		},
		tags() {
			let tags = this.customTags;

			if(this.embeddedTags) {
				tags = tags.concat(this.embeddedTags);
			}

			return tags;
		},
		combinedAcceptedFileExtensions() {
			let extensions = [...this.defaultAcceptedFileExtensions, ...this.acceptedFileExtensions];

			return extensions.filter((ext, index) => {
				return extensions.indexOf(ext) === index;
			});
		},
		combinedPhotoConverters() {
			return { ...this.photoConverters, ...this.defaultPhotoConverters };
		},
		chooseFileMessageComputed() {
			let imgFormats = ['jpg', 'png'];
			let converters = Object.keys(this.combinedPhotoConverters);
			if(converters.includes('webp')) {
				imgFormats.push('webp');
			}
			if(converters.includes('heic')) {
				imgFormats.push('heic');
			}

			return this.chooseFileMessage ?? this.i18n('uploader.chooseFileMessage', {
				imgFormats: imgFormats.join(', ')
			});
		}
	},
	methods: {
		nextStep() {
			this.currentStep++;
		},
		prevStep() {
			this.currentStep--;
		},
		setFiles(files) {
			this.uploads = files.map((file) => {
				return createUpload({
					file,
					description: file.name
				});
			});
		},
		startOver() {
			this.uploads = [];
			this.currentStep = 1;
		},
		updateCustomTags(customTags) {
			this.customTags = customTags;
		},
		uploadFinished(upload) {
			if(upload) {
				this.$emit('upload-finished', upload);
			}
		},
		allUploadsFinished() {
			this.$emit('all-uploads-finished', this.uploads);
		},
		stop() {
			this.$emit('stop');
		},
		cancel() {
			let subComponents = this.$refs['active-component'];
			subComponents?.forEach(component => {
				if(component.cancel) {
					component.cancel();
				}
			});
		}
	}
};
</script>

<style scoped>
	.sub-headline {
		margin-top: -1em;
	}
	.uploader-header {
		flex-wrap: nowrap;
	}

	.main-header {
		margin-top: 0.5em;
	}
	.header {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		padding: 0 16px 0 16px;
		margin-bottom: 1em;
		text-align: center;
	}
	.sub-header {
		color: rgba(0,0,0,.6);
		font-size: .875rem;
	}
</style>