import getGradeValue from './utils/get-grade-value.js';

$.SubjectManagement.AUTO_COMPLETE_FIELDS = ['Grade', 'Teacher', 'Home Room'];
$.SubjectManagement.BOOLEAN_FIELDS = ['On All', 'Individualize Staff'];
$.SubjectManagement.PER_BATCH_PROPERTIES = ['Teacher Priority', 'On All', 'Individualize Staff'];
$.SubjectManagement.RESORT_PROPERTIES = ['Teacher Priority', 'First Name', 'Last Name'];
$.SubjectManagement.DEFAULT_PROJECT_FIELD_SETS = {
	editableFields: [
		{
			name: 'Grade',
			group: true
		},
		{
			name: 'Teacher',
			group: true
		},
		{
			name: 'Home Room',
			group: true,
			ifInDataWithout: 'Teacher'
		},
		{
			name: 'Student ID',
			group: false,
			ifInData: true
		}
	],
	defaultFilter: $.SubjectManagement.AUTO_COMPLETE_FIELDS,
	filterByOptions: [
		{
			name: 'Default (guess based on teacher/grade data)',
			color: 'green'
		},
		{
			name: 'Teacher',
			color: 'red'
		},
		{
			name: 'Grade',
			color: 'blue'
		},
		{
			name: 'Grade and sort by Teacher',
			color: 'yellow'
		},
		{
			name: 'Teacher,Grade',
			display: 'Teacher and Grade',
			color: 'purple'
		}
	]
};

$.SubjectManagement.getDefaultFilter = function(project) {
	if(project && project.projectType && $.projectTypeFieldSets && $.projectTypeFieldSets[project.projectType]) {
		if(!project.fieldSet) {
			project.fieldSet = $.extend(true, {}, $.projectTypeFieldSets[project.projectType]);
		}

		return project.fieldSet.defaultFilter || $.SubjectManagement.AUTO_COMPLETE_FIELDS;
	} else {
		return $.SubjectManagement.AUTO_COMPLETE_FIELDS;
	}
};

$.SubjectManagement.createFieldMap = function(template) {
	var fields = template.subject_fields;
	fields.forEach(function(field) {
		if(!field.field_key && field.label) {
			field.field_key = field.label;
		}
	});

	var fieldIdMap = {};
	fields.forEach(function(field) {
		fieldIdMap[field.id] = field.field_key;
	});
	return fieldIdMap;
};
$.SubjectManagement.createFieldLabelMap = function(fieldIdMap, subjects) {
	var fieldLabelMap = {};

	for(var id in fieldIdMap) {
		var label = fieldIdMap[id];

		if($.isInit(fieldLabelMap[label])) {
			var originalId = fieldLabelMap[label];

			var originalCount = 0;
			var newCount = 0;
			subjects.forEach(function(subject) {
				if(subject.properties && $.isInit(subject.properties[originalId])) {
					originalCount++;
				}

				if(subject.properties && $.isInit(subject.properties[id])) {
					newCount++;
				}
			});

			if(newCount > originalCount) {
				fieldLabelMap[label] = id;
			}
		} else {
			fieldLabelMap[label] = id;
		}
	}

	return fieldLabelMap;
};
$.SubjectManagement.populateSubjectsWithTemplate = function(subjects, template, fieldIdMap, placeholderSubjects) {
	var dataSubjects = [];

	if(!fieldIdMap) {
		fieldIdMap = $.SubjectManagement.createFieldMap(template);
	}
	var fieldLabelMap = $.SubjectManagement.createFieldLabelMap(fieldIdMap, subjects);

	for(var i = 0; i < subjects.length; i++) {
		var subject = subjects[i];
		var newSubject = {
			id: subject.id,
			createdAt: subject.created_at,
			updatedAt: subject.updated_at,
			poseFlags: subject.pose_flags,
			photos_count: subject.photos_count,
			studio_orders_count: subject.studio_orders_count
		};

		var primaryPhoto = null;
		if(subject.photos.length) {
			primaryPhoto = $.SubjectManagement.getPrimaryPhoto(subject);
			newSubject.photos = subject.photos;
			if(primaryPhoto) {
				$.SubjectManagement.populateSubjectWithPrimaryPhoto(newSubject, primaryPhoto);
			}
		}

		$.SubjectManagement.populateSubjectWithTemplate(newSubject, fieldIdMap, fieldLabelMap, subject);
		if(subject.orders) {
			newSubject.orders = subject.orders;
			// Sometimes PLIC is wrong
			newSubject.studio_orders_count = subject.orders.length;
		}
		if(subject.primary_photo) {
			newSubject.primary_photo = subject.primary_photo;
		} else if(subject.primary_photo_id) {
			newSubject.primary_photo_id = subject.primary_photo_id;
		}
		newSubject.photos_count = subject.photos.length;

		if(primaryPhoto) {
			dataSubjects.push(newSubject);
		} else if(placeholderSubjects) {
			placeholderSubjects.push(newSubject);
		}
	}

	return dataSubjects;
};
$.SubjectManagement.populateSubjectWithTemplate = function(newSubject, fieldIdMap, fieldLabelMap, originalSubject) {
	var criteriaId = fieldLabelMap.Criteria;
	for(var id in fieldIdMap) {
		var label = fieldIdMap[id];
		if(label == 'photo' || label == 'photoCdnUrl') {
			continue;
		}

		if(typeof originalSubject.properties[id] != 'undefined') {
			var prop = originalSubject.properties[id];
			if(label == 'Teacher Priority') {
				if(!$.isInit(prop) && criteriaId && $.isInit(originalSubject.properties[criteriaId])) {
					prop = originalSubject.properties[criteriaId];
				}

				if(prop && prop.toLowerCase) {
					var lowerCaseProp = prop.toLowerCase();
					if(['staff', 'instructor', 'instructors aid', 'asst. principal'].indexOf(lowerCaseProp) !== -1) {
						prop = 1;
					}
				}

				prop = parseInt(prop);
				if(isNaN(prop)) {
					prop = 0;
				}
			} else if($.SubjectManagement.BOOLEAN_FIELDS.indexOf(label) != -1) {
				if(!$.isInit(prop)) {
					prop = false;
				} else if(prop == 'true') {
					prop = true;
				} else {
					prop = false;
				}
			}

			if(!newSubject[label]) {
				newSubject[label] = prop;
			}
		} else if(!$.isInit(newSubject[label])) {
			newSubject[label] = null;
		}
	}

	if(typeof newSubject['Title'] == 'undefined') {
		newSubject['Title'] = null;
	}
	if(!newSubject['Access Code'] && originalSubject.access_code) {
		newSubject['Access Code'] = originalSubject.access_code.code;
	}
	if(!$.isInit(newSubject['Teacher Priority']) && $.isInit(newSubject['Criteria'])) {
		prop = newSubject['Criteria'];

		prop = parseInt(prop);
		if(isNaN(prop)) {
			prop = 0;
		}

		newSubject['Teacher Priority'] = prop;
	}
};
$.SubjectManagement.populateSubjectsWithPrimaryPhoto = function(subjects) {
	for(var i = 0; i < subjects.length; i++) {
		var subject = subjects[i];

		var primaryPhoto = $.SubjectManagement.getPrimaryPhoto(subject);
		if(primaryPhoto) {
			$.SubjectManagement.populateSubjectWithPrimaryPhoto(subject, primaryPhoto);
		}
	}
};
$.SubjectManagement.getPrimaryPhoto = function(subject) {
	if(subject.photos && subject.photos.length) {
		var primary_pose_id = null;
		if(subject.pose_flags && $.primarySubjectPoseFlag && subject.pose_flags[$.primarySubjectPoseFlag]) {
			primary_pose_id = subject.pose_flags[$.primarySubjectPoseFlag];
		} else if(subject.primary_photo) {
			primary_pose_id = subject.primary_photo.id;
		} else if(subject.primary_photo_id) {
			primary_pose_id = subject.primary_photo_id;
		}

		var primaryPhoto = null;
		for(var j = 0; j < subject.photos.length; j++) {
			var photo = subject.photos[j];
			if(primary_pose_id == photo.id) {
				// PLIC requires a primary photo, but if we upload (or BP syncs) a group photo, that shouldn't really be treated as the primary
				// If we have an individual_category photo assigned to the subject, we can be pretty sure that they meant to mark the group as the primary since we upload the latest as the primary
				if(!(photo.category === 'group_category' && subject.primary_photo_id === photo.id && subject.photos.filter(photo => photo.category !== 'individual_category').length === subject.photos.length)) {
					primaryPhoto = photo;
					break;
				}
			} else if(photo.category == 'individual_category') {
				// Don't break since we want the last photo which matches to be picked
				primaryPhoto = photo;
			}
		}

		return primaryPhoto;
	} else {
		return null;
	}
};
$.SubjectManagement.populateSubjectWithPrimaryPhoto = function(subject, primaryPhoto) {
	subject.photo = primaryPhoto.id;
	subject.photoCdnUrl = primaryPhoto.cdn_url;
	if(primaryPhoto.photoCdnUrl) {
		subject.photoCdnUrl = primaryPhoto.photoCdnUrl;
	}
	subject.photo_name = primaryPhoto.upload_file_name;
	subject.yearbookPhoto = primaryPhoto;

	if(!subject.photos) {
		subject.photos = [primaryPhoto];
	}

	subject.yearbookCrop = null;
	var expectedSubjectCropName = $.expectedSubjectCropName;
	if(expectedSubjectCropName === undefined) {
		expectedSubjectCropName = 'Yearbook 8 x 10';
	}
	subject.photos.forEach(function(photo) {
		if(photo.photo_crops) {
			for(var i = 0; i < photo.photo_crops.length; i++) {
				var crop = photo.photo_crops[i];
	
				// Match if we:
				// 		1) Have an expected name and match it
				//      2) Use default expectedSubjectCropName and have an 0.8 crop ratio
				// 		3) Don't have an expected name
				// 			a) Are the default crop if one exists
				//          b) The first crop if no default crop specified
				if(crop.name == expectedSubjectCropName || (expectedSubjectCropName === null && (!photo.default_photo_crop_id || photo.default_photo_crop_id == crop.id))) {
					photo.yearbookCrop = crop;
					if(photo == primaryPhoto) {
						subject.yearbookCrop = photo.yearbookCrop;
						break;
					}
				} else if(expectedSubjectCropName === 'Yearbook 8 x 10' && photo.width && photo.height) {
					var croppedWidth = photo.width * parseFloat(crop.width);
					var croppedHeight = photo.height * parseFloat(crop.height);

					var croppedRatio = croppedWidth / croppedHeight;
					if($.isWithinDiff(croppedRatio, 0.8, 0.01)) {
						photo.yearbookCrop = crop;
						if(photo == primaryPhoto) {
							subject.yearbookCrop = photo.yearbookCrop;

							// Do not break in case exact name match is found later
						}
					}
				}
			}
		}
	});
};
$.SubjectManagement.getSubjectIssues = function(subject, cellWidth, options) {
	options = $.extend({
		ratio: 0.8,
		subjects: []
	}, options);

	var includeDPI = true;
	if(!cellWidth) {
		cellWidth = 2;
		includeDPI = false;
	}

	if(options.ratio === '*') {
		options.ratio = 0.8;
	}

	var issues = [];
	if(!subject) {
		return issues;
	} else if(subject.yearbookPhoto) {
		var photo = subject.yearbookPhoto;
		if(photo.width && photo.height) {
			var photoWidth = photo.width;
			var photoHeight = photo.height;
			var flippedPhotoWidth = photo.height;
			var flippedPhotoHeight = photo.width;

			if(options.crop) {
				photoWidth = photoWidth * options.crop.width;
				photoHeight = photoHeight * options.crop.height;
				flippedPhotoWidth = flippedPhotoWidth * options.crop.width;
				flippedPhotoHeight = flippedPhotoHeight * options.crop.height;
			} else if(options.crop === undefined && photo.yearbookCrop) {
				photoWidth = photoWidth * photo.yearbookCrop.width;
				photoHeight = photoHeight * photo.yearbookCrop.height;
				flippedPhotoWidth = flippedPhotoWidth * photo.yearbookCrop.width;
				flippedPhotoHeight = flippedPhotoHeight * photo.yearbookCrop.height;
			}

			var dpi = Math.min(photoWidth, photoHeight) / cellWidth;
			if(dpi < $.getStudioSetting('minimumSubjectDPI', 150)) {
				if(includeDPI) {
					issues.push('Low resolution photo (' + Math.round(photoWidth) + ' x ' + Math.round(photoHeight) + ' and ' + Math.round(dpi) + ' DPI)');
				} else {
					issues.push('Low resolution photo (' + Math.round(photoWidth) + ' x ' + Math.round(photoHeight) + ')');
				}
			}

			// We check if the inverse relationship is true as well for 90 degree rotated images
			if(options.ratio !== null && !$.isWithinDiff(photoWidth / photoHeight, options.ratio, 0.01) && !$.isWithinDiff(flippedPhotoWidth / flippedPhotoHeight, options.ratio, 0.01)) {
				var ratioMessage;
				if(options.ratio == 0.8) {
					ratioMessage = '8 x 10';
				} else if(options.ratio == 1.25) {
					ratioMessage = '10 x 8';
				} else if(options.ratio == 0.67) {
					ratioMessage = '4 x 6';
				} else {
					ratioMessage = 'aspect ratio of ' + options.ratio;
				}

				issues.push('Incorrect aspect ratio (Must be ' + ratioMessage + ')');
			}
		}
	} else if(!options.ignoreMissingPhoto) {
		issues.push('Missing photo');
	}

	var matchingSubjectNames = options.subjects.filter(function(otherSubject) {
		if(subject.id === otherSubject.id) {
			return false;
		}

		if(!subject['First Name'] || !subject['Last Name'] || $.trim(subject['First Name']) != $.trim(otherSubject['First Name']) || $.trim(subject['Last Name']) != $.trim(otherSubject['Last Name'])) {
			return false;
		}

		// They need to have at least one of: Teacher, Grade, Home Room, Student ID to match on before we consider it a duplicate
		if(!subject['Teacher'] && !subject['Grade'] && !subject['Home Room'] && !subject['Student ID']) {
			return false;
		}

		if(subject['Teacher'] != otherSubject['Teacher'] || subject['Grade'] != otherSubject['Grade'] || subject['Home Room'] != otherSubject['Home Room']) {
			return false;
		}

		if($.trim(subject['Student ID']) != $.trim(otherSubject['Student ID'])) {
			return false;
		}

		return true;
	});
	if(matchingSubjectNames.length) {
		issues.push('Duplicate subject with the same name, grade, and teacher');
	} else if($.trim(subject['Student ID'])) {
		var matchingSubjectIds = options.subjects.filter(function(otherSubject) {
			if(subject.id === otherSubject.id) {
				return false;
			}
	
			return $.trim(subject['Student ID']) && $.trim(subject['Student ID']) == $.trim(otherSubject['Student ID']);
		});

		if(matchingSubjectIds.length) {
			issues.push('Duplicate subject with the same student id');
		}
	}

	var firstName = $.trim(subject['First Name'] || '').toLowerCase();
	var lastName = $.trim(subject['Last Name'] || '').toLowerCase();
	if(firstName === 'test' || lastName === 'test') {
		issues.push('name of test');
	}

	return issues;
};
$.SubjectManagement.getSubjectIssuesPopup = function(subject, cellWidth, options) {
	var issues = $.SubjectManagement.getSubjectIssues(subject, cellWidth, options);
	if(issues.length) {
		var outerDiv = $('<div>');

		if(issues.length > 1) {
			outerDiv.append('<div class="ui small header">Subject Issues</div>');

			var issuesHTML = $('<div class="ui bulleted list">').appendTo(outerDiv);
			for (var i = 0; i < issues.length; i++) {
				$('<div class="item">').text(issues[i]).appendTo(issuesHTML);
			}
		} else {
			var content = issues[0];
			if(content.indexOf('(') != -1) {
				// Break up into header/content sections
				var index = content.indexOf('(');

				var header = content.substr(0, index - 1);
				content = content.substr(index + 1, content.length - index - 2);

				outerDiv = $('<div class="header">').text(header).add($('<div class="content">').text(content));
			} else {
				outerDiv.text(issues[0]);
			}
		}

		return outerDiv;
	} else {
		return null;
	}
};
$.SubjectManagement.recreateBatchesFromFilter = function(settings) {
	var batches = null;
	var filter = settings.filter;

	var groupBy = filter;
	var sortBy = null;
	var i;
	if($.isArray(filter)) {
		groupBy = filter[0];
		for(i = 0; i < filter.length; i++) {
			var newSet = $.SubjectManagement.getBatchesFromFilter(filter[i], settings);
			if(!batches || newSet.length > batches.length) {
				batches = newSet;
				groupBy = filter[i];
			}
		}
	} else {
		var searchStr = ' and sort by ';
		var index = groupBy.indexOf(searchStr);
		if(index != -1) {
			groupBy = filter.substr(0, index);
			sortBy = filter.substr(index + searchStr.length);
		}

		batches = $.SubjectManagement.getBatchesFromFilter(groupBy, settings);
	}

	// Sort batches and replace with only id's
	for(i = 0; i < batches.length; i++) {
		var batch = batches[i];
		$.SubjectManagement.sortSubjects(batch.subjects, sortBy);

		if(settings.addAppSpecificSubjectsData !== false) {
			batch.subjects = $.SubjectManagement.getAppSpecificSubjectsData(batch.subjects);
		}
	}
	batches.batchNameSort();

	if(settings.job) {
		settings.job.groupBy = groupBy;
		settings.job.sortBy = sortBy;
	}

	if(settings.remoteCall !== false) {
		$.ajax({
			url: 'ajax/recreateBatchFilter.php',
			dataType: 'json',
			data: {
				jobId: settings.jobId,
				batches: JSON.stringify(batches),
				groupBy: groupBy,
				sortBy: sortBy
			},
			type: 'POST',
			success: settings.success,
			error: settings.error
		});
	}

	if(settings.job) {
		$.plicAPI({
			method: 'projects/' + settings.job.plicProjectId + '/workflow-actions',
			params: {
				workflow_action: {
					plic_app_id: $.plicSlug,
					action: 'book_imported',
					user_id: $.CurrentUser.plicUserId
				}
			}
		});
	}

	return batches;
};
$.SubjectManagement.updateEditedSubjectBatches = function(settings) {
	var shouldSave = false;
	var updatedBatches = [];
	var existingBatchFilter = $.SubjectManagement.getExistingBatchFilter(settings.batches, settings.subjects, settings.groupBy);

	// Look through all batches for name changes
	settings.batches.forEach(function(batch) {
		var newBatches = $.SubjectManagement.getBatchesFromFilter(existingBatchFilter, {
			subjects: batch.subjects
		});

		newBatches.forEach(function(newBatch) {
			if(batch.name != newBatch.name) {
				// Check if subjects are actually updated
				var updatedBatchSubjects = batch.subjects.filter(function(subject) {
					return subject.updatedAt && window.moment(subject.updatedAt).unix() - settings.lastSubjectUpdateAt > 10
				});
				if(updatedBatchSubjects / batch.subjects.length < 0.5) {
					return;
				}

				// Make sure we aren't just renaming a Teacher - Grade back to be only Teacher
				if(batch.name == (newBatch.name + ' - ' + newBatch.subjects[0].Grade)) {
					return;
				}

				if(newBatch.subjects.length / batch.subjects.length > 0.9) {
					batch.name = newBatch.name;
					shouldSave = true;
					updatedBatches.push(batch);
				}
			}
		});
	});

	if(shouldSave) {
		var saveBatches = settings.batches.map(function(batch) {
			return $.SubjectManagement.getAppSpecificBatchData(batch);
		});

		$.ajax({
			url: 'ajax/saveBatch.php',
			dataType: 'json',
			data: {
				jobId: settings.jobId,
				batches: JSON.stringify(saveBatches)
			},
			type: 'POST',
			success: function() {
				if(settings.success) {
					settings.success({
						updatedBatches: updatedBatches
					});
				}
			},
			error: settings.error
		});
	}
};
$.SubjectManagement.getExistingBatchFilter = function(batches, subjects, groupByFields) {
	if(groupByFields) {
		return groupByFields;
	}

	var maxValueField, maxValueCount;
	for(var i = 0; i < $.SubjectManagement.AUTO_COMPLETE_FIELDS.length; i++) {
		var field = $.SubjectManagement.AUTO_COMPLETE_FIELDS[i];
		var values = [];

		for(var j = 0; j < subjects.length; j++) {
			var subject = subjects[j];
			var value = subject[field];

			for(var k = 0; k < batches.length; k++) {
				var batch = batches[k];
				if(batch.name == value) {
					if(values.indexOf(value) == -1) {
						values.push(value);
					}
					break;
				}
			}
		}

		if(!maxValueField || values.length > maxValueCount) {
			maxValueField = field;
			maxValueCount = values.length;
		}
	}

	return maxValueField;
};
$.SubjectManagement.sortSubjects = function(subjects, priorityField) {
	subjects.sort(function(a, b) {
		return $.SubjectManagement.compareSubjects(a, b, priorityField);
	});

	return subjects;
};
$.SubjectManagement.DESC_STRING = ' (DESC)';
$.SubjectManagement.compareSubjects = function(a, b, priorityField) {
	var priorityFields;
	if(priorityField) {
		priorityFields = priorityField.split(',');
	}

	var positiveResult = 1;
	if(a['Teacher Priority'] && !b['Teacher Priority']) {
		return -positiveResult;
	} else if(!a['Teacher Priority'] && b['Teacher Priority']) {
		return positiveResult;
	} else if(a['Teacher Priority'] && b['Teacher Priority'] && a['Teacher Priority'] != b['Teacher Priority']) {
		return (a['Teacher Priority'] < b['Teacher Priority']) ? -positiveResult : positiveResult;
	} else if(priorityFields && priorityFields.length) {
		for(var i = 0; i < priorityFields.length; i++) {
			var field = priorityFields[i];
			positiveResult = 1;
			if(field.indexOf($.SubjectManagement.DESC_STRING) > 0 && field.indexOf($.SubjectManagement.DESC_STRING) === field.length - $.SubjectManagement.DESC_STRING.length) {
				positiveResult = -1;
				field = field.substr(0, field.length - $.SubjectManagement.DESC_STRING.length);
			}

			var aPriorityField = $.SubjectManagement.getSubjectProperty(a, field);
			var bPriorityField = $.SubjectManagement.getSubjectProperty(b, field);

			if(field === 'Grade') {
				var aGradeValue = $.SubjectManagement.getGradeValue(aPriorityField);
				var bGradeValue = $.SubjectManagement.getGradeValue(bPriorityField);

				if(aGradeValue !== false && bGradeValue === false) {
					return -1;
				} else if(aGradeValue === false && bGradeValue !== false) {
					return 1;
				} else if(aGradeValue !== false && bGradeValue !== false) {
					if(aGradeValue === bGradeValue) {
						continue;
					} else {
						return aGradeValue < bGradeValue ? -positiveResult : positiveResult;
					}
				}
			}

			if(!aPriorityField && bPriorityField) {
				return positiveResult;
			} else if(aPriorityField && !bPriorityField) {
				return -positiveResult;
			} else if(aPriorityField && bPriorityField) {
				let result = aPriorityField.localeCompare(bPriorityField, undefined, { sensitivity: 'base', ignorePunctuation: true });
				if(result !== 0) {
					return result < 0 ? -positiveResult : positiveResult;
				}
			}
		}

		// Fall back to result without priority fields
		return $.SubjectManagement.compareSubjects(a, b);
	} else {
		var aLastName = a['Last Name'] ? a['Last Name'].toLowerCase() : null;
		var bLastName = b['Last Name'] ? b['Last Name'].toLowerCase() : null;
		var aFirstName = a['First Name'] ? a['First Name'].toLowerCase() : null;
		var bFirstName = b['First Name'] ? b['First Name'].toLowerCase() : null;

		// Then by last name
		let result;
		if(!aLastName && bLastName) {
			return positiveResult;
		} else if(aLastName && !bLastName) {
			return -positiveResult;
		} else if(aLastName && bLastName && (result = aLastName.localeCompare(bLastName, undefined, { sensitivity: 'base', ignorePunctuation: true })) !== 0) {
			return result;
		}
		// Otherwise by first name
		else if(!aFirstName && bFirstName) {
			return positiveResult;
		} else if(aFirstName && !bFirstName) {
			return -positiveResult;
		} else if(aFirstName && bFirstName && (result = aFirstName.localeCompare(bFirstName, undefined, { sensitivity: 'base', ignorePunctuation: true })) !== 0) {
			return result;
		} else {
			// Same first and last name
			return 0;
		}
	}
};
$.SubjectManagement.getSubjectProperty = function(subject, field) {
	var value = '';
	
	if(field === 'Primary Pose Name') {
		if(subject.yearbookPhoto) {
			value = subject.yearbookPhoto.upload_file_name || '';
		}
	} else {
		value = subject[field] || '';
	}

	return $.trim(value.toLowerCase());
};
// Make sure to update generateFormattedData in Element's reports
$.SubjectManagement.getGradeValue = function(value) {
	return getGradeValue(value);
};

const GRADE_CONVERSIONS = {
	'k': 'Kindergarten',
	'pk': 'Pre-Kindergarten',
	'ps': 'Preschool',
	'sta': 'Staff',
	'fac': 'Faculty',
	'ee': 'Early Education'
};
$.SubjectManagement.getGradeWordDisplay = function(grade) {
	var gradeValue = parseInt(grade);
	if(!isNaN(gradeValue)) {
		var numToWord = $.numToWord2(gradeValue);
		if(numToWord) {
			return numToWord.toTitleCase();
		} else if(grade == '-1') {
			return 'Kindergarten';
		} else if(grade == '-2') {
			return 'Pre-Kindergarten';
		}
	}
	
	var lowerGrade = grade.toLowerCase().trim();
	if(GRADE_CONVERSIONS[lowerGrade]) {
		return GRADE_CONVERSIONS[lowerGrade];
	}

	return null;
};
$.SubjectManagement.getGradeOrdinalDisplay = function(grade) {
	let gradeValue = parseInt(grade);
	if(!isNaN(gradeValue)) {
		if(grade == '-1') {
			return 'Kindergarten';
		} else if(grade == '-2') {
			return 'Pre-Kindergarten';
		}

		let numToWord = $.numToOrdinal(gradeValue);
		if(numToWord) {
			return numToWord;
		}
	}
	
	let lowerGrade = grade.toLowerCase().trim();
	if(GRADE_CONVERSIONS[lowerGrade]) {
		return GRADE_CONVERSIONS[lowerGrade];
	}

	return null;
};
$.SubjectManagement.getSubjectSortedPosition = function(compareSubjects, sortSubject, priorityField) {
	for(var i = 0; i < compareSubjects.length; i++) {
		var compareSubject = compareSubjects[i];
		if(sortSubject == compareSubject || compareSubject.skipSort) {
			continue;
		}

		if($.SubjectManagement.compareSubjects(sortSubject, compareSubject, priorityField) == -1) {
			return i;
		}
	}

	return compareSubjects.length;
};
$.SubjectManagement.getBatchesFromFilter = function(filter, settings) {
	var filters = filter.split(',');

	var batchesAssoc = {};
	var subjects = $.merge([], settings.subjects);
	if(settings.placeholderSubjects && $.getProjectSetting && $.getProjectSetting('includeNoImageSubjects', false)) {
		settings.placeholderSubjects.forEach(subject => {
			if(subjects.indexOfMatch(subject, 'id') !== -1) {
				return;
			}

			subjects.push($.extend(true, {}, subject));
		});
	}
	var name, batchSubjects, subject, i;
	for(i = 0; i < subjects.length; i++) {
		subject = subjects[i];
		var fields = filters.map(function(filter) {
			return subject[filter];
		});

		for(var j = 0; j < fields.length; j++) {
			if(!$.isInit(fields[j])) {
				fields.splice(j, 1);
				j--;
			}
		}
		while(fields.indexOf(null) != -1) {
			fields.removeItem(null);
		}

		var data = fields.join(' - ');
		if(!$.isInit(data)) {
			data = '';
		}

		if(!batchesAssoc[data]) {
			batchesAssoc[data] = [];
		}

		batchesAssoc[data].push(subject);
	}

	// For teachers, see if we need to split up grades at all
	if($.arrayEquals(filters, ['Teacher']) && settings.filterByTeacherGrade !== false) {
		for(name in batchesAssoc) {
			batchSubjects = batchesAssoc[name];
			var gradeBatches = $.SubjectManagement.getBatchesFromFilter('Grade', {
				subjects: batchSubjects
			});
			if(gradeBatches.length <= 1) {
				continue;
			}

			var gradeBatchesWithSubjects = gradeBatches.filter(function(gradeBatch) {
				return gradeBatch.subjectCount > 2;
			});
			if(gradeBatches.length != gradeBatchesWithSubjects.length) {
				continue;
			}

			var gradeBatchesWithStaff = gradeBatches.filter(function(gradeBatch) {
				return gradeBatch.name.toLowerCase().indexOf('staff') !== -1;
			});
			if(gradeBatchesWithStaff.length) {
				continue;
			}

			delete batchesAssoc[name];
			gradeBatches.forEach(function(gradeBatch) {
				batchesAssoc[name + ' - ' + gradeBatch.name] = gradeBatch.subjects;
			});
		}
	}

	var batches = [];
	for(name in batchesAssoc) {
		batchSubjects = batchesAssoc[name];

		for(i = 0; i < subjects.length; i++) {
			subject = subjects[i];

			if((subject['On All'] == 'true' || subject['On All'] === true) && batchSubjects.indexOfMatch(subject, 'id') == -1) {
				batchSubjects.push(subject);
			}
		}

		if(!name && batchSubjects.length == subjects.length) {
			name = 'Project';
		}

		batches.push({
			name: name,
			subjects: batchSubjects,
			subjectCount: batchSubjects.length
		});
	}

	return batches;
};
$.SubjectManagement.addSubjectsToBatches = function(batches, subjects, placeholderSubjects, job, options) {
	// Make a copy so we can remove
	var remainingSubjects = $.merge([], subjects);
	var existingSubjects = [];
	for (var i = 0; i < batches.length; i++) {
		var batch = batches[i];

		for (var j = 0; j < batch.subjects.length; j++) {
			var batchSubject = batch.subjects[j];
			var id = batchSubject.id;

			// Find subject which matches id
			var k;
			for(k = 0; k < subjects.length; k++) {
				if (subjects[k].id == id) {
					var subject = $.extend(true, {}, subjects[k]);

					if(batchSubject.properties) {
						// Sometimes can end up as '"{}"'
						while(typeof batchSubject.properties == 'string') {
							batchSubject.properties = JSON.parse(batchSubject.properties);
						}
						if(typeof batchSubject.properties['Teacher Priority'] === 'string') {
							let value = parseInt(batchSubject.properties['Teacher Priority']);
							if(isNaN(value)) {
								value = 0;
							}
							batchSubject.properties['Teacher Priority'] = value;
						}
						$.extend(subject, batchSubject.properties);
					}

					batch.subjects[j] = subject;
					existingSubjects.push(subjects[k]);
					remainingSubjects.removeItem(subjects[k]);
					id = null;
					break;
				}
			}

			if($.isInit(id) && placeholderSubjects) {
				for(k = 0; k < placeholderSubjects.length; k++) {
					var placeholder = placeholderSubjects[k];
					if (placeholder.id == id) {
						if(batchSubject.properties) {
							if(typeof batchSubject.properties == 'string') {
								batchSubject.properties = JSON.parse(batchSubject.properties);
							}
							$.extend(placeholder, batchSubject.properties);
						}

						batch.subjects[j] = placeholder;
						// Remove from placeholders since we are using it like a normal subject
						placeholderSubjects.removeItem(placeholder);
						subjects.push($.extend(true, {}, placeholder));
						id = null;
						break;
					}
				}
			}

			if($.isInit(id)) {
				batch.subjects.splice(j, 1);
				j--;
			}
		}

		batch.subjectCount = batch.subjects.length;
	}

	// We had a case where job.groupBy wasn't being loaded correctly so it just threw subjects in the completely wrong batches.  Avoid that!
	var lastSubjectUpdateAt;
	if(job && job.lastSubjectUpdateAt && existingSubjects.length && job.groupBy) {
		lastSubjectUpdateAt = window.moment(job.lastSubjectUpdateAt).unix();
		var updatedSubjects = [];
		existingSubjects.forEach(function(subject) {
			if(subject.updatedAt && window.moment(subject.updatedAt).unix() - lastSubjectUpdateAt > 10) {
				updatedSubjects.push(subject);
			}
		});

		if(updatedSubjects.length) {
			$.SubjectManagement.updateEditedSubjectBatches({
				jobId: job.jobId,
				subjects: updatedSubjects,
				batches: batches,
				groupBy: job.groupBy,
				sortBy: job.sortBy,
				lastSubjectUpdateAt: lastSubjectUpdateAt,
				success: function(data) {
					if(options && options.success) {
						options.success({
							updatedBatches: data.updatedBatches
						});
					}
				},
				error: function() {}
			});
		}
	}

	$.SubjectManagement.sortSubjects(remainingSubjects);
	return remainingSubjects;
};
$.SubjectManagement.getAppSpecificBatchData = function(batch) {
	return {
		batchId: batch.id,
		name: batch.name,
		subjects: $.SubjectManagement.getAppSpecificSubjectsData(batch.subjects)
	};
};
$.SubjectManagement.getAppSpecificSubjectsData = function(subjects) {
	return subjects.map(function(subject) {
		return $.SubjectManagement.getAppSpecificSubjectData(subject);
	});
};
$.SubjectManagement.getAppSpecificSubjectData = function(subject) {
	var saveSubject = {
		id: subject.id,
		batchProperties: {}
	};

	$.SubjectManagement.PER_BATCH_PROPERTIES.forEach(function(name) {
		if($.isInit(subject[name])) {
			saveSubject.batchProperties[name] = subject[name];
		}
	});

	return saveSubject;
};

$.SubjectManagement.toNameCase = function(name) {
	return name.toLowerCase().replace(/(^| )(\w)/g, function (x) {
		return x.toUpperCase();
	});
};