// Only include stuff required for core display - do not include UI libs that won't be used in renderer
import Vue from 'vue';
if(!window.Vue) {
	window.Vue = Vue;
}

import $ from 'jquery';
if(!window.$) {
	window.$ = window.jQuery = $;
}

import moment from 'moment';
window.moment = moment;

import Decimal from 'decimal.js';
window.Decimal = Decimal;

import Polyglot from 'node-polyglot';
window.Polyglot = Polyglot;
require('./locales');

// Use require so window.$ runs first
require('../../js/utils.js');
require('../../js/ext/ajax.js');
require('../../js/ext/jquery.js');
require('../../js/ext/prototype.js');
require('../../js/ext/format.js');

// We are using a monkey-patched version of Semantic UI - do not replace with npm version unless we are upgrading to modern version
require('../../js/lib/semantic.min.js');
require('../../css/lib/semantic.min.css');
require('../../css/common/semantic-ui-fixes.css');
require('../../css/common/colors.css');
require('../../css/common/colors-extras.css');
require('../../css/fonts/index.css');
require('../../js/ext/form-rules.js');

// Storing for later so we can restore the link to the correct accordion after re-importing jquery-ui
$.fn.semanticAccordion = $.fn.accordion;