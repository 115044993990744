<template>
	<v-app>
		<RebrandBanner :message="REBRAND_BANNER_MESSAGE" />
		<v-app-bar app color="headerPrimary" class="main-toolbar" v-if="!isLogin">
			<img class="app-logo" alt="Product Designer by Captura" :src="logoUrl">
			<v-toolbar-title class="headline white--text hidden-sm-and-down">{{ toolbarName }}</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-toolbar-items>
				<v-btn v-for="item in toolbarItems" :key="item.name" :to="item.to" color="primary">
					<span>{{ item.text }}</span>
				</v-btn>
			</v-toolbar-items>
		</v-app-bar>

		<v-main>
			<v-container class="app-content" fluid fill-height>
				<transition name="fade" v-if="!loading">
					<router-view></router-view>
				</transition>

				<v-layout justify-center align-center v-if="loading">
					<v-progress-circular indeterminate></v-progress-circular>
				</v-layout>

				<div class="bottom-bar" v-if="showBottomBar">
					<div class="bottom-logo"><img src="../PlicCanvas/css/common/captura-logo.svg" /></div>
					<div class="policies">
						<a href="/Eula.html" style="margin-right: 1em" class="privacy-policy">License Agreement</a>
						<a href="/PrivacyPolicy.html" class="privacy-policy">Privacy Policy</a>
						<a href="/CookiePolicy.html" style="margin-left: 1em" class="privacy-policy">Cookie Policy</a>
					</div>
					<v-spacer />
				</div>
			</v-container>
		</v-main>

		<update-notification />
	</v-app>
</template>

<script>
import RebrandBanner from '../PlicCanvas/vue/common/RebrandBanner.vue';
import UpdateNotification from '../PlicCanvas/vue/common/UpdateNotification';
import { REBRAND_BANNER_MESSAGE } from '../vue/constants';

export default {
	name: 'App',
	data() {
		return {
			loading: false,
			errorMessage: null,
			appName: 'Product Designer by Captura',
			REBRAND_BANNER_MESSAGE
		};
	},
	computed: {
		routeDisplay() {
			switch(this.$route.name) {
				case 'login':
					return 'Login';
				case 'subject-uploader':
					return 'Portrait Uploader';
			}

			return null;
		},
		toolbarName() {
			if(this.routeDisplay) {
				return this.routeDisplay;
			} else {
				return '';
			}
		},
		toolbarItems() {
			let items = [];

			if([].includes(this.$route.name)) {
				items.push({
					text: 'Login',
					to: {
						name: 'login'
					}
				});
			}

			return items;
		},
		isLogin() {
			return this.$route.name === 'login' || this.$route.name === 'reset-password';
		},
		showBottomBar() {
			return true;
		}
	},
	watch: {
		routeDisplay() {
			this.updateTitle();
		}
	},
	methods: {
		updateTitle() {
			if(this.routeDisplay) {
				document.title = this.appName + ' - ' + this.routeDisplay;
			} else {
				document.title = this.appName;
			}
		}
	},
	mounted() {
		this.updateTitle();
	},
	components: {
		UpdateNotification,
		RebrandBanner
	}
}
</script>

<style>
	html {
		overflow: auto !important;
	}
	html, body, .v-application {
		height: 100%;
		height: 100vh;
		height: -webkit-fill-available;

		background: var(--color-light-background) !important;
		background-color: var(--color-light-background) !important;
	}

	.v-application > .v-application--wrap {
		min-height: 100vh !important;
		min-height: -webkit-fill-available !important;
	}

	.v-application .main-toolbar .app-logo {
		max-height: 90%;
		margin-right: 1em;
	}

	.v-application .app-content {
		text-align: center;
		flex-direction: column;
	}
	.app-content.fill-height > .layout {
		height: initial;
		width: 100%;
	}

	.app-content > .layout:nth-child(2) {
		display: none;
	}
	.fade-enter-active, .fade-leave-active {
		transition-property: opacity;
		transition-duration: 0.3s;
	}

	.fade-enter-active {
		transition-delay: 0.3s;
	}

	.fade-enter, .fade-leave-active {
		opacity: 0
	}
</style>

<style scoped>
.v-main {
	color: var(--color-zinc-500);
}
.bottom-bar {
	display: flex;
	width: 100%;
	font-size: 16px;
	color: var(--color-zinc-500);
}

.bottom-bar > div {
	flex: 1 1 100px;
}

.bottom-bar > div {
	display: flex;
	flex: 1 1 100px;
	align-items: center;
	justify-content: center;
}
.bottom-bar .bottom-logo {
	justify-content: start;
}
.bottom-logo > img {
	height: 30px;
}

.policies:deep(a) {
	color: var(--color-zinc-500);
}
</style>