import fixAlbumName from './utils/fix-album-name';
import isUserTag from './utils/is-user-tag';

$(document).ready(function() {
	$.fn.PhotoPicker = function (options) {
		var me = this[0];
		$.extend(me, {
			loadAlbums: function(options) {
				this.startLoading();
				this.clearPhotos();

				this.initAlbumPicker();

				this.albumPicker.loadAlbums($.extend({}, options, {
					autoSetAlbums: false
				}), function(albums) {
					if(albums.length || !options.createDefault) {
						$.convertAlbumsToSubFolders(albums);
						me.setAlbums(albums, options);
					} else {
						me.albumPicker.insertNewAlbum(null, options, options.createDefault);
					}
				}, function() {
					me.showError();
				});
				this.lastLoadAlbums = options;
				if(options.project) {
					this.project = options.project;
				}
			},
			setAlbums: function(albums, options) {
				this.initAlbumPicker();
				if(this.onSetAlbums) {
					if(this.onSetAlbums(albums, options) === false) {
						return;
					}
				}

				this.albumPicker.setAlbums(albums, options);
				this.afterSetAlbums(albums);
			},
			getRecursiveAlbums: function(categories) {
				return categories.reduce((categories, category) => {
					if(category.subCategories) {
						categories.push(...this.getRecursiveAlbums(category.subCategories));
					} else {
						categories.push(category);
					}
	
					return categories;
				}, []);
			},
			setAlbumCategories: function(albums) {
				this.initAlbumPicker();
				this.albumPicker.setAlbumCategories(albums);
				this.afterSetAlbums(albums);
			},
			initAlbumPicker: function() {
				if(!this.albumPicker) {
					this.albumPicker = new $.AlbumCategoryPicker(this.id, {
						categories: options ? options.categories : null,
						defaultCategory: options ? options.defaultCategory : null,
						fluid: false,
						onCategoryChange: function (category) {
							if(category) {
								if(me.loadFromPicker) {
									me.loadPhotos({
										albumId: category.id
									});
								}

								$(me).find('.requireAlbum').removeClass('disabled');

								if(category.tags && category.tags.indexOf('Default Album') !== -1) {
									$(me).find('.notDefaultAlbum').addClass('disabled');
								} else {
									$(me).find('.notDefaultAlbum').removeClass('disabled');
								}
							} else {
								$(me).find('.requireAlbum').addClass('disabled');
							}

							if(me.onAlbumChange) {
								me.onAlbumChange(category);
							}

							// Make sure delete is only visible with more than one album
							if(this.currentCategories && this.currentCategories.length > 1) {
								$(me).find('.requireMultipleAlbums').removeClass('disabled');
							} else {
								$(me).find('.requireMultipleAlbums').addClass('disabled');
							}

							if(me.moveToAlbumDropdown) {
								me.moveToAlbumDropdown.currentCategory = category;
							}
						},
						onCategoriesChange: function(categories) {
							$(me).find('.requireAlbumsLoaded').removeClass('disabled');

							if(categories.length) {
								me.emptyAlbumsMessage.hide();
							} else {
								me.emptyAlbumsMessage.show();
								me.emptyPhotosMessage.hide();
								$(me).find('.requireMultipleAlbums').addClass('disabled');
							}

							if(me.onCategoriesChange) {
								me.onCategoriesChange(categories);
							}
							if(me.moveToAlbumDropdown) {
								me.moveToAlbumDropdown.setCategories(categories);
							}
						}
					});
					$(this.albumPicker).addClass('headerButton').css('margin-right', '0.5em');

					if (this.header) {
						this.header.prepend(this.albumPicker);
					} else {
						if(this.backButton) {
							$(this.albumPicker).css('margin-left', '0.2em').insertAfter($(this).children().first());
						} else {
							$(this).prepend(this.albumPicker);
						}
					}
				}

				$(this).find('.requireAlbumsLoaded').addClass('disabled');
			},
			afterSetAlbums: function(albums) {
				if(!albums.length) {
					me.emptyAlbumsMessage.show();
					this.stopLoading();

					if(this.searchBox) {
						this.searchBox.hide();
					}
					this.clearTagFilters();
				}

				$(this).find('.requireAlbumsLoaded').removeClass('disabled');
			},
			refreshPhotos: function() {
				this.loadPhotos(this.lastLoadPhotos);
			},
			loadPhotos: function(options) {
				this.startLoading();
				this.clearPhotos();

				if(options.albumId) {
					$.loadAlbum({
						albumId: options.albumId,
						jobId: this.jobId,
						success: function (data) {
							data.sort(function (a, b) {
								let uploadNameCompare = (a.upload_file_name || '').toLowerCase().localeCompare((b.upload_file_name || '').toLowerCase());
								if(uploadNameCompare === 0) {
									return a.upload_file_size - b.upload_file_size;
								} else {
									return uploadNameCompare;
								}
							});
							me.showPhotos(data);
						},
						error: function() {
							me.showError();
						}
					});

					this.lastLoadPhotos = options;
				} else if(options.ajax) {
					$.ajax($.extend(options.ajax, {
						success: function (data) {
							if(options.getData) {
								data = options.getData.call(me, data);
							}

							me.showPhotos(data);
						},
						error: function() {
							me.showError();
						}
					}));
				}
			},
			setPhotos: function(photos) {
				this.showPhotos(photos);
			},
			showPhotos: function(photos) {
				this.photos = photos;
				this.changeVisiblePhotos(photos);
				this.updateTagFilters();

				if(photos.length) {
					this.emptyPhotosMessage.hide();
				} else {
					this.emptyPhotosMessage.show();

					if(this.searchBox) {
						this.searchBox.hide();
					}
					if(this.selectionHeader) {
						this.selectionHeader.hide();
					}
				}

				if(this.selectAllCheckbox) {
					this.selectAllCheckbox.checkbox('uncheck');
				}
			},
			getAllPhotos: function() {
				return this.photos;
			},
			clearTagFilters: function() {
				this.tagPicker.find('.tagLabel').remove();
			},
			updateTagFilters: function() {
				this.clearTagFilters();

				let photos = this.photos;
				this.constantFilters.forEach(function(filter) {
					photos = photos.filter(filter);
				});

				var tags = this.getTagsFromPhotos(photos);
				let tagKeys = Object.keys(tags);
				tagKeys.caseInsensitiveSort();
				tagKeys.forEach(tag => {
					let photos = tags[tag] || [];
					var button = $('<div class="ui tiny basic button tagLabel">').text(tag).prepend('<i class="tag icon"></i>').click(function () {
						$(this).toggleClass('blue');
						me.changeTagFilter();
					}).attr('data-tooltip', i18n.t('photoPicker.photosCount', {count: photos.length})).data('photos', photos);
					this.tagPicker.append(button);
				});
			},
			getTagsFromPhotos: function(photos) {
				var tags = {};
				for(let i = 0; i < photos.length; i++) {
					var photo = photos[i];

					var photoTags = $.PhotoPickerUtils.getPhotoTags(photo);
					photoTags.forEach(function(tag) {
						if(tags[tag]) {
							tags[tag].push(photo);
						} else {
							tags[tag] = [photo];
						}
					});
				}

				return tags;
			},
			changeTagFilter: function() {
				let photos = [];

				var selectedTags = this.tagPicker.children('.blue.button');
				if(selectedTags.length) {
					selectedTags.each(function () {
						var tagPhotos = $(this).data('photos');

						if (tagPhotos) {
							for (let i = 0; i < tagPhotos.length; i++) {
								var photo = tagPhotos[i];

								if (photos.indexOf(photo) == -1) {
									photos.push(photo);
								}
							}
						}
					});

					this.changeVisiblePhotos(photos);
				} else if(this.currentPhotos.length !== this.photos.length) {
					this.stopFiltering();
				}
			},
			removePhotoFromTagFilters: function(photo) {
				this.tagPicker.children('.tagLabel').each(function() {
					let photos = $(this).data('photos');
					if(!photos) {
						return;
					}

					var index = photos.indexOf(photo);
					if(index != -1) {
						photos.splice(index, 1);

						if(photos.length) {
							$(this).attr('data-tooltip', i18n.t('photoPicker.photosCount', {count: photos.length}));
						} else {
							$(this).remove();
							me.changeTagFilter();
						}
					}
				});
			},
			changeVisiblePhotos: function(photos) {
				this.clearPhotos();
				if(photos) {
					this.constantFilters.forEach(function(filter) {
						photos = photos.filter(filter);
					});
				}
				this.currentPhotos = photos;

				// Update selected photos
				if(this.requireSelectionVisible) {
					for(let i = 0; i < this.selectedPhotos.length; i++) {
						var photo = this.selectedPhotos[i];
						if(photos.indexOfMatch(photo, 'id') === -1) {
							this.selectedPhotos.splice(i, 1);
							i--;
						}
					}
				}
				this.updateSelectedCount();

				if(photos.length) {
					this.startLoading();
					this.picker.hide();

					this.showAdditionalPhotos();
				} else {
					this.stopLoading();

					if(me.onImagesDoneLoading) {
						me.onImagesDoneLoading();
					}
				}
			},
			addConstantFilter: function(filter) {
				this.constantFilters.push(filter);
				this.changeVisiblePhotos(this.photos);
				this.updateTagFilters();
			},
			removeConstantFilter: function(filter) {
				this.constantFilters.removeItem(filter);
				this.changeVisiblePhotos(this.photos);
				this.updateTagFilters();
			},
			clearPhotos: function() {
				if(!this.currentPhotos || this.currentPhotos.length || this.photosVisible || this.lastAppendStart) {
					this.picker.find('.hasPopup').popup('destroy');
					if (this.picker.hasClass('masonry')) {
						this.picker.masonry('destroy').removeClass('masonry');
					}
					this.picker.empty();
					this.maxPhotosVisible = 0;
					this.photosVisible = 0;
					this.lastAppendStart = null;
					this.currentPhotos = [];
					this.cachedCards = {};
				}
			},
			showAdditionalPhotos: function() {
				if(this.photosVisible >= this.currentPhotos.length) {
					return;
				}

				// Masonry animates them in, so need to have delay before we can start showing photos again
				var currentTime = new Date().getTime();
				if((this.lastAppendStart && (currentTime - this.lastAppendStart) < 1000) || this.isLoadingImages) {
					return;
				}
				this.lastAppendStart = currentTime;

				var startPhotos = this.photosVisible;
				this.maxPhotosVisible += this.photosPerPage;
				var addedCards = $();
				for (let i = this.photosVisible; i < this.currentPhotos.length && i < this.maxPhotosVisible; i++) {
					var photo = this.currentPhotos[i];
					let card = this.createPhotoCard(photo);
					if (card) {
						this.picker.append(card);
						addedCards = addedCards.add(card);

						if(this.selectedPhotos.indexOf(photo) !== -1) {
							$(card).addClass('blue').find('.corner.label').removeClass('hidden');
						}

						var heightRatio = 1 / card.aspectRatio;
						var cardWidth = $(card).css('width');

						var paddingBottom;
						if(cardWidth.indexOf('%') != -1) {
							var widthPercentage = parseFloat(cardWidth.replace('%', ''));
							paddingBottom = (heightRatio * widthPercentage) + '%';
						} else {
							var widthPx = parseFloat(cardWidth.replace('px', ''));
							paddingBottom = (heightRatio * widthPx) + 'px';
						}

						$(card).css('padding-bottom', paddingBottom);
						$(card).css('max-height', paddingBottom);
					}
					this.photosVisible++;
				}

				this.showPhotosFinish(startPhotos === 0, addedCards);
			},
			showPhotosFinish: function(firstAdd, addedCards) {
				if (this.onAddPhotosFinish) {
					this.onAddPhotosFinish();
				}

				if(firstAdd) {
					var picker = this.picker;
					window.setTimeout(function() {
						picker.show().masonry({
							itemSelected: '.item'
						}).addClass('masonry');
					}, 0);
					this.setupVisibility();

					this.stopLoading();

					if (this.onImagesDoneLoading) {
						this.onImagesDoneLoading();
					}
					if(this.searchBox) {
						this.searchBox.show();
					}
					if(this.selectionHeader) {
						this.selectionHeader.show();
					}
				} else {
					addedCards.show();
					this.picker.masonry('appended', addedCards);
				}

				if(this.photosLoader) {
					this.photosLoader.remove();
					this.photosLoader = null;
				}
			},
			setupVisibility: function() {
				if($(this.picker).hasClass('activeVisibility')) {
					return;
				}

				var div = this;
				// NOTE: Anything that breaks this probably breaks Subject Management as well!
				$(this.picker).visibility({
					once: false,
					observeChanges: true,
					continuous: true,
					context: this.visibilityScrollRelativeTo,
					onBottomVisible: function() {
						if(!$(me).isAttached()) {
							return;
						} else if((me.picker.height() - $(div.visibilityScrollRelativeTo).scrollTop()) >  $(div.visibilityScrollRelativeTo).height()) {
							return;
						}

						me.showAdditionalPhotos();
					}
				}).addClass('activeVisibility');
			},
			createPhotoCard: function(photo) {
				var imageUrl = this.getImage(photo, true);
				if(!imageUrl) {
					console.warn('No valid imageUrl found for ', photo);
					return null;
				}

				if(this.cachedCards[photo.id]) {
					let card = this.cachedCards[photo.id];
					delete this.cachedCards[photo.id];

					return card;
				}

				let card = $('<div class="ui card">')[0];
				if(photo.id) {
					$(card).attr('id', 'photo-' + photo.id);
				}
				var imageWrapper = $('<div class="image">').appendTo(card);
				var imageElem = $('<img style="pointer-events: none" />').appendTo(imageWrapper);
				var cornerLabel = $('<a class="ui corner label hidden blue"><i class="checkmark icon"></i></a>').appendTo(card);
				$(card).data('photo', photo);
				card.photo = photo;
				card.imgElem = imageElem[0];
				card.imgWrapper = imageWrapper[0];
				card.aspectRatio = photo.width / photo.height;

				if(this.selection) {
					imageWrapper.click(function () {
						$(card).toggleClass('blue');
						cornerLabel.toggleClass('hidden');

						var index = me.selectedPhotos.indexOfMatch(photo, 'id');
						if ($(card).hasClass('blue')) {
							if (index == -1) {
								me.selectedPhotos.push(photo);
							}
						} else {
							if (index != -1) {
								me.selectedPhotos.splice(index, 1);
							}
						}

						me.updateSelectedCount();
					});

					if(this.selectedPhotos.indexOfMatch(photo, 'id') !== -1) {
						$(card).addClass('blue');
						cornerLabel.removeClass('hidden');
					}
				}

				if(this.cardButtons) {
					for(let i = 0; i < this.cardButtons.length; i++) {
						var button = this.getCommonFloatingButtons(photo, this.cardButtons[i]);
						if(button) {
							$(card).append(this.createFloatingButton(button, card));
						}
					}

					$(card).hover(function() {
						var hovers = this.getHoverButtons();
						// Don't chain these together if user closeButton in and out really fast
						hovers.transition('reset');

						// Drop in animation
						hovers.transition('drop in');
					}, function() {
						var hovers = this.getHoverButtons();
						hovers.transition('drop out');
					});
				}

				// Add dummy so button is not .card children:last
				$('<div>').appendTo(card);

				var popup = this.getCardPopup(card, photo);
				if(popup) {
					$(card).popup(popup).addClass('hasPopup');
				}

				$.extend(card, {
					getHoverButtons: function() {
						return $(this).find('.button').not('.permanentlyVisible');
					},
					moveToAlbum: function(albumId, chain) {
						this.startLoading(true);

						var oldAlbumId = me.lastLoadPhotos.albumId;
						var photo = this.photo;
						chain.add($.getPlicAPI({
							method: 'album-photo-assignments',
							params: {
								album_photo_assignment: {
									album_id: albumId,
									photo_id: photo.id
								}
							},
							success: function (data) {
								// Purposely am not using the chain here so that the delete happens soon after the insert to make it closer to atomic
								$.plicAPI({
									method: 'albums/' + oldAlbumId + '/photos/' + photo.id,
									type: 'DELETE',
									accept: 'application/vnd.plic.io.v1+json',
									success: function() {
										card.remove();
									},
									error: function() {
										$.Alert('Error', 'Failed to move image');
										card.stopLoading();
									}
								});
							},
							error: function () {
								$.Alert('Error', 'Failed to move image');
								card.stopLoading();
							}
						}));
					},
					openTagEditor: function() {
						$(this).findSelf('.hasPopup').popup('hide');

						if(me.project) {
							me.loadSubjects(card, function(subjects) {
								$.PhotoPickerUtils.loadTagEditor({
									load: function(options, onSuccess, onError) {
										$.plicAPI({
											method: 'photos/' + photo.id,
											params: {
												include_album_imports: true
											},
											type: 'GET',
											success: function(data) {
												options.albumImports = data.album_imports;
												options.users = data.users;
												onSuccess();
											},
											error: function() {
												onError();
											}
										});
									},
									photo: photo,
									subjects: subjects,
									plicProjectId: me.project.plicProjectId,
									imageUrl: imageUrl,
									allowRotateImage: me.allowRotateImage,
									updateTags: function() {
										card.updatePopup();
										me.updateTagFilters();
									},
									startLoading: function() {
										card.startLoading();
									},
									stopLoading: function() {
										card.stopLoading();
									},
									updatePhoto: function(photo) {
										card.photo = photo;
										$(card).data('photo', photo);
										card.aspectRatio = photo.width / photo.height;
										imageUrl = me.getImage(photo, true);
										imageElem.LoadImage(imageUrl, {
											onComplete: function () {
												card.stopLoading();
												if(me.picker.hasClass('masonry')) {
													me.picker.masonry('layout');
												}
											}
										});
									}
								});
							}, function() {
								$.Alert('Error', 'Failed to load subjects for tagging');
								card.stopLoading();
							});
						} else {
							$.PhotoPickerUtils.loadTagEditor({
								load: function(options, onSuccess, onError) {
									$.plicAPI({
										method: 'photos/' + photo.id,
										params: {
											include_album_imports: true
										},
										type: 'GET',
										success: function(data) {
											options.albumImports = data.album_imports;
											options.users = data.users;
											onSuccess();
										},
										error: function() {
											onError();
										}
									});
								},
								photo: photo,
								imageUrl: imageUrl,
								allowRotateImage: me.allowRotateImage,
								updateTags: function() {
									card.updatePopup();
									me.updateTagFilters();
								},
								startLoading: function() {
									card.startLoading();
								},
								stopLoading: function() {
									card.stopLoading();
								},
								updatePhoto: function(photo) {
									card.photo = photo;
									card.aspectRatio = photo.width / photo.height;
									imageUrl = me.getImage(photo, true);
									imageElem.LoadImage(imageUrl, {
										onComplete: function () {
											card.stopLoading();
											if(me.picker.hasClass('masonry')) {
												me.picker.masonry('layout');
											}
										}
									});
								}
							});
						}
					},
					updatePopup: function() {
						var popup = me.getCardPopup(card, photo);
						if($(this).hasClass('hasPopup')) {
							$(this).popup('destroy');
						}

						if(popup) {
							$(this).popup(popup);

							if(!$(this).hasClass('hasPopup')) {
								$(this).addClass('hasPopup');
							}
						} else if($(this).hasClass('hasPopup')) {
							$(this).removeClass('hasPopup');
						}
					},
					startLoading: function(pendingRemove) {
						$(card).append('<div class="ui active inverted dimmer"><div class="ui text loader"></div></div>');

						if(pendingRemove) {
							var index = me.selectedPhotos.indexOfMatch(photo, 'id');
							if (index != -1) {
								me.selectedPhotos.splice(index, 1);
								me.updateSelectedCount();
							}
							$(card).removeClass('blue');
							$(card).find('.corner.label').toggleClass('hidden');
							card.pendingRemove = true;
						}
					},
					stopLoading: function() {
						$(card).find('.dimmer').remove();
					},
					isLoading: function() {
						return $(card).find('.dimmer').length > 0;
					},
					remove: function() {
						if($(card).isAttached()) {
							me.photosVisible--;
						} else {
							var otherCard = $(me.picker).find('#photo-' + photo.id)[0];
							if(otherCard) {
								card = otherCard;
							}
						}
						$(card).findSelf('.hasPopup').popup('destroy');

						me.photos.removeItem(photo);
						me.currentPhotos.removeItem(photo);
						me.selectedPhotos.removeItem(photo);
						if(me.picker.hasClass('masonry')) {
							me.picker.masonry('remove', card).masonry('layout');
						} else {
							$(card).remove();
						}
						me.removePhotoFromTagFilters(photo);
						me.updateSelectedCount();
					},
					checkPhotoDimensions: function() {
						// We only want to fix rotation if we have a width/height to fix AND we need the image to not be a square
						// We were still getting some shuffling from this triggering with width 591 and height 592.  It will always trigger since the ratios are within 0.01 of each other
						if(this.photo.width && this.photo.height && !$.isWithinDiff(this.photo.width, this.photo.height, 2) && this.imgElem.naturalWidth && this.imgElem.naturalHeight) {
							var naturalAspectRatio = this.imgElem.naturalWidth / this.imgElem.naturalHeight;
							var invertedAspectRatio = this.photo.height / this.photo.width;

							// We have a rotated image
							if($.isWithinDiff(naturalAspectRatio, invertedAspectRatio, 0.01) && (naturalAspectRatio < 0.99 || naturalAspectRatio > 1.01)) {
								var temp = this.photo.height;
								this.photo.height = this.photo.width;
								this.photo.width = temp;

								this.aspectRatio = this.photo.width / this.photo.height;
								this.updateWideClass();

								if(me.picker.hasClass('masonry')) {
									me.picker.masonry('layout');
								}
							}
						} else if(!this.photo.width && !this.photo.height && this.imgElem.naturalWidth && this.imgElem.naturalHeight) {
							this.photo.width = this.imgElem.naturalWidth;
							this.photo.height = this.imgElem.naturalHeight;
							
							this.aspectRatio = this.photo.width / this.photo.height;
							this.updateWideClass();
							if(me.picker.hasClass('masonry')) {
								me.picker.masonry('layout');
							}
						}
					},
					showCrop: function() {
						if(this.photo.crop) {
							var imageRect = this.imgWrapper.getBoundingClientRect();
							var crop = this.photo.crop;
							var cropWidth = crop.width * 100 + '%';
							var cropHeight = crop.height * 100 + '%';
							var cropLeft = crop.left * 100 + '%';
							var cropTop = crop.top * 100 + '%';

							$(this.imgElem).css({
								position: 'relative',
								width: cropWidth,
								height: cropHeight,
								left: cropLeft,
								top: cropTop
							});

							var naturalWidth = this.imgElem.naturalWidth;
							var naturalHeight = this.imgElem.naturalHeight;

							var resultWidth = naturalWidth / crop.width;
							var resultHeight = naturalHeight / crop.height;
							var resultAspectRatio = resultWidth / resultHeight;
							
							var expectedHeight = imageRect.width / resultAspectRatio;
							$(this.imgWrapper).css({
								height: expectedHeight
							});

							if(me.picker.hasClass('masonry')) {
								me.picker.masonry('layout');
							}
						}
					},
					updateWideClass: function() {
						$(this).removeClass('widePhoto').removeClass('extraWidePhoto');

						if(!isNaN(this.aspectRatio) && this.aspectRatio > 5) {
							$(this).addClass('extraWidePhoto');
						} else if(this.aspectRatio > 3 || me.allWidePhotos) {
							$(this).addClass('widePhoto');
						}
					}
				}, this.cardFunctions);

				card.updateWideClass();
				if(this.preLoadImages) {
					card.startLoading();
					imageElem.LoadImage(imageUrl, {
						onComplete: function () {
							$(card).css('max-height', '');
							$(card).css('padding-bottom', '');
							if(!card.pendingRemove) {
								card.stopLoading();
							}

							card.checkPhotoDimensions();
							card.showCrop();

							if(me.onImageDoneLoading) {
								me.onImageDoneLoading(card);
							}
						},
						onFailed: function() {
							$(card).css('max-height', '');
							$(card).css('padding-bottom', '');
							if(!card.pendingRemove) {
								card.stopLoading();
							}

							if(me.picker.hasClass('masonry')) {
								me.picker.masonry('layout');
							}
						}
					});
				} else {
					imageElem.attr('src', imageUrl);
				}

				return card;
			},
			getCommonFloatingButtons: function(photo, name) {
				var button = name;
				if(typeof name == 'string') {
					switch (name) {
						case 'used':
							if(photo.used && photo.used.count) {
								button = {
									position: 'top left',
									text: photo.used.count,
									popup: this.getTimesUsedText(photo.used),
									color: 'blue'
								};
							} else {
								return null;
							}
							break;
						case 'permanent-used':
							if(photo.used && photo.used.count) {
								button = {
									position: 'top left',
									text: photo.used.count,
									popup: this.getTimesUsedText(photo.used),
									color: 'blue',
									permanent: true
								};
							} else {
								return null;
							}
							break;
						case 'delete': case 'delete-show-stats':
							if(photo.used && photo.used.count) {
								button = {
									position: 'top left',
									text: photo.used.count,
									popup: this.getTimesUsedText(photo.used),
									color: 'blue',
									permanent: name === 'delete-show-stats'
								};
							} else {
								button = {
									position: 'top right',
									icon: 'remove',
									color: 'red',
									popup: i18n.t('photoPicker.removeImage'),
									onClick: function (card, photo) {
										card.startLoading();
										$.plicAPI({
											method: 'albums/' + this.lastLoadPhotos.albumId + '/photos/' + photo.id,
											type: 'DELETE',
											accept: 'application/vnd.plic.io.v1+json',
											success: function (data) {
												card.remove();
											},
											error: function () {
												$.Alert('Error', 'Failed to delete image');
												card.stopLoading();
											}
										});
									}
								};
							}
							break;
						case 'deny':
							button = {
								position: 'top right',
								icon: 'remove',
								color: 'red',
								popup: i18n.t('photoPicker.denyCandid'),
								onClick: function(card, photo, button) {
									card.deny();
								}
							};
							break;
						case 'zoomInOnly':
							button = {
								position: 'bottom left',
								icon: 'zoom in',
								color: 'blue',
								popup: i18n.t('photoPicker.viewLargeImage'),
								onClick: function(card, photo) {
									this.openFancyBox(card, photo, {
										download: false
									});
								}
							};
							break;
						case 'zoomIn':
							button = {
								position: 'bottom left',
								icon: 'zoom in',
								color: 'blue',
								popup: i18n.t('photoPicker.viewLargeImage'),
								onClick: function(card, photo) {
									this.openFancyBox(card, photo, {
										download: true
									});
								}
							};
							break;
						case 'tag':
							button = {
								position: 'bottom right',
								icon: 'tag',
								color: 'yellow',
								popup: i18n.t('photoPicker.editTags'),
								onClick: function(card, photo) {
									card.openTagEditor();
								}
							};
							break;
					}
				}

				switch(button.position) {
					case 'topLeft': case 'top left':
						button.position = {
							position: 'absolute',
							left: 0,
							top: 0,
							display: 'none'
						};
						break;
					case 'topRight': case 'top right':
						button.position = {
							position: 'absolute',
							right: 0,
							top: 0,
							display: 'none'
						};
						break;
					case 'botLeft': case 'bottomLeft': case 'bot left': case 'bottom left':
						button.position = {
							position: 'absolute',
							left: 0,
							bottom: 0,
							display: 'none'
						};
						break;
					case 'botRight': case 'bottomRight': case 'bot right': case 'bottom right':
						button.position = {
							position: 'absolute',
							right: 0,
							bottom: 0,
							display: 'none'
						};
						break;
				}

				return button;
			},
			getTimesUsedText: function(used) {
				if(this.showPageUsed && used.pages) {
					let pages = used.pages.map(page => {
						if($.PAGE_OFFSET) {
							page -= $.PAGE_OFFSET;
						}

						return page;
					}).filter((page, index, self) => self.indexOf(page) === index);
					pages.sort();
					pages = pages.map(page => page <= 0 ? 'Cover' : page);

					return i18n.t('photoPicker.timesUsedAlt', {
						pages: pages.joinAnd(', ', ' and '),
						smart_count: pages.length
					});
				} else {
					return i18n.t('photoPicker.timesUsed', {count: used.count});
				}
			},
			createFloatingButton: function(definition, card) {
				var button = $('<div class="ui circular button">');
				if(definition.color) {
					button.addClass(definition.color);
				}

				if(definition.icon) {
					button.addClass('icon');
					$('<i class="icon">').addClass(definition.icon).appendTo(button);
				}

				if(definition.text) {
					button.text(definition.text);
				}

				button.css(definition.position);

				if(definition.onClick) {
					button.click(function() {
						definition.onClick.call(me, card, $(card).data('photo'), button);
					});
				} else {
					button.addClass('label');
				}

				if(definition.popup) {
					button.popup({
						content: definition.popup,
						// Something is causing this button to disappear after hovering over it when permanent is set to true
						onHide: function() {
							if(definition.permanent) {
								$(button).show();
							}
						}
					}).addClass('hasPopup');
				}

				if(definition.permanent) {
					button.addClass('permanentlyVisible visible');
				} else {
					button.addClass('hidden');
				}

				return button;
			},
			setSelected: function(photos) {
				this.picker.find('.card').removeClass('blue').find('.corner.label').addClass('hidden');

				this.selectedPhotos = photos;
				this.selectedPhotos.forEach(function(selectedPhoto) {
					let card = $(me.picker).find('#photo-' + selectedPhoto.id);
					card.addClass('blue').find('.corner.label').removeClass('hidden');
				});
				this.updateSelectedCount();
			},
			eachSelected: function(callback) {
				var cards = $(this.picker).find('.ui.card');
				var selectedPhotos = $.merge([], this.selectedPhotos);
				selectedPhotos.forEach(function(photo) {
					var selectedCard = cards.filter('#photo-' + photo.id)[0];
					if(!selectedCard) {
						selectedCard = me.createPhotoCard(photo);
						me.cachedCards[photo.id] = selectedCard;
					}

					if(selectedCard) {
						callback.call(me, selectedCard, photo);
					} else {
						console.warn('no card for ', photo);
					}
				});
			},
			clearSelected: function() {
				this.selectedPhotos.length = 0;
				this.picker.find('.card').removeClass('blue').find('.corner.label').addClass('hidden');

				this.updateSelectedCount();
			},
			updateSelectedCount: function() {
				if(this.selectedCountLabel) {
					if(this.photos?.length) {
						this.selectedCountLabel.text(i18n.t('misc.selectedOf', {
							count: this.selectedPhotos.length,
							total: this.photos.length
						}));
					} else {
						this.selectedCountLabel.text(i18n.t('misc.selected', {
							count: this.selectedPhotos.length
						}));
					}
				}

				if(this.headerButtons) {
					for(let i = 0; i < this.headerButtons.length; i++) {
						var button = this.headerButtons[i];
						if(button.requireSelection) {
							if (this.selectedPhotos.length) {
								button.div.removeClass('disabled');
							} else {
								button.div.addClass('disabled');
							}
						}
					}
				}

				if(this.onSelectionChanged) {
					this.onSelectionChanged(this.selectedPhotos);
				}
			},
			addHeaderButton: function(button, index) {
				var buttonDiv;
				if(button.url) {
					buttonDiv = $('<a class="ui button headerButton">');
				} else {
					buttonDiv = $('<div class="ui button headerButton">');
				}
				
				if(button.color) {
					buttonDiv.addClass(button.color);
				}
				if(button.name && !$.isInit(button.input)) {
					buttonDiv.text(button.name);
				}

				if(button.icon) {
					buttonDiv.addClass('icon');
					if(button.name && !$.isInit(button.input)) {
						buttonDiv.addClass('labeled');
					}
					buttonDiv.prepend('<i class="' + button.icon + ' icon">')
				}
				if(button.rightIcon) {
					buttonDiv.addClass('right labeled icon');
					buttonDiv.append('<i class="' + button.rightIcon + ' icon">')
				}
				if(button.addClass) {
					buttonDiv.addClass(button.addClass);
				}

				if(button.requireSelection) {
					buttonDiv.addClass('requireSelection');
					if(!this.selectedPhotos.length) {
						buttonDiv.addClass('disabled');
					}
				}
				if(button.requireAlbum) {
					buttonDiv.addClass('disabled requireAlbum');
				}
				if(button.requireMultipleAlbums) {
					buttonDiv.addClass('requireMultipleAlbums');
				}
				if(button.requireAlbumsLoaded) {
					buttonDiv.addClass('requireAlbumsLoaded');
				}
				if(button.onClick) {
					buttonDiv.click(function () {
						if($(this).hasClass('loading')) {
							return;
						}

						button.onClick.call(me, this);
					});
				}

				if(button.popup) {
					buttonDiv.popup({
						content: button.popup
					});
				}

				if($.isInit(button.input)) {
					var inputDiv = $('<div class="ui small action input">');
					if(button.name) {
						$('<div class="ui label">').text(button.name).appendTo(inputDiv);
						inputDiv.addClass('labeled');
					}

					var input = $('<input type="text" readonly="true">').appendTo(inputDiv);
					if(typeof button.input == 'string') {
						input.val(button.input);
					}
					input.on('click', function() {
						this.setSelectionRange(0, this.value.length)
					});
					inputDiv.append(buttonDiv);

					buttonDiv = inputDiv;
				}
				if(button.rightAligned) {
					buttonDiv.css('float', 'right');
				}

				var lastHeaderButton = $(this).find('.headerButton').last();
				if(lastHeaderButton.length) {
					if(index) {
						lastHeaderButton = $(this).find('.headerButton').eq(index);
					}
					
					buttonDiv.insertAfter(lastHeaderButton);
				} else {
					if (this.header) {
						this.header.append(buttonDiv);
					} else {
						$(this).append(buttonDiv);
					}
				}
				button.div = buttonDiv;

				if(button.onCreate) {
					button.onCreate.call(this, buttonDiv, button);
				}

				if(!this.headerButtons) {
					this.headerButtons = [button];
				} else if(this.headerButtons.indexOf(button) == -1) {
					this.headerButtons.push(button);
				}

				return buttonDiv;
			},
			openFancyBox: function(card, photo, options) {
				if(!options) {
					options = {};
				}

				let photos;
				var index = this.photos.indexOf(photo);
				if(index != -1) {
					photos = [];
					// Want all photos to show up in same order shown
					for(let i = index; i < this.photos.length; i++) {
						photos.push(this.photos[i]);
					}

					for(let i = 0; i < index; i++) {
						photos.push(this.photos[i]);
					}
				} else {
					// Want my photo at front, but don't mess with actual photos array
					photos = $.merge([], this.photos);
					photos.unshift(photo);
				}

				var imageSet = [];
				for(let i = 0; i < photos.length; i++) {
					var url = this.getImage(photos[i], false);
					if(url) {
						imageSet.push({
							href: url,
							photo: photos[i],
							title: '&nbsp;',
							delayedTitle: this.getTitle(photos[i])
						});
					}
				}

				if(imageSet.length) {
					$.fancybox.open(imageSet, {
						padding : 0,
						type: 'image',
						afterShow: function () {
							var fancyBox = this;
							// Do this way for xss reasons
							var titleDiv = this.skin.find('.fancybox-title .child');
							titleDiv.text(this.delayedTitle);
							if (options.download) {
								titleDiv.append(' <a href="' + $.getPlicDownloadUrl(this.photo) + '" download target="_blank">(' + i18n.t('misc.download') + ')</a>');
							}

							if(options.onTitleInit) {
								options.onTitleInit.call(me, titleDiv, this.photo, {
									remove: function() {
										if(fancyBox.group.length < 2) {
											$.fancybox.close();
										}

										fancyBox.group.splice(fancyBox.index, 1);
										$.fancybox.jumpto(fancyBox.index);
									}
								});
							}

							var innerWrapper = this.skin.find('.fancybox-inner');
							innerWrapper.addClass('disableSaveAs');
						}
					});
				}
			},
			search: function(value) {
				this.searchIcon.hide();
				this.searchStopIcon.show();

				this.filter(function(photo) {
					var criteria = me.getSearchCriteria(photo);
					if(criteria) {
						return criteria.indexOf(value) != -1;
					} else {
						return false;
					}
				});
				this.searching = true;
			},
			stopSearching: function() {
				this.searchIcon.show();
				this.searchStopIcon.hide();
				this.searchInput.val('');
				$(this.searchInput).data('lastQuery', null);
				this.stopFiltering();
				this.searching = false;
			},
			filter: function(tester) {
				var filteredPhotos = [];
				for(let i = 0; i < this.photos.length; i++) {
					var photo = this.photos[i];
					if(tester.call(this, photo)) {
						filteredPhotos.push(photo);
					}
				}

				this.changeVisiblePhotos(filteredPhotos);
			},
			stopFiltering: function() {
				this.changeVisiblePhotos(this.photos);
			},
			startLoading: function() {
				$(this).css('min-height', '200px');
				this.loader.addClass('active');
				this.errorLoadingMessage.hide();
			},
			stopLoading: function() {
				$(this).css('min-height', '');
				this.loader.removeClass('active');
			},
			isLoading: function() {
				return this.loader.hasClass('active');
			},
			showError: function() {
				this.errorLoadingMessage.show();

				this.stopLoading();
				if(this.searchBox) {
					this.searchBox.hide();
				}
				if(this.selectionHeader) {
					this.selectionHeader.hide();
				}
			},
			loadSubjects: function(card, onSuccess, onError) {
				var project = this.project;
				if(project.subjects) {
					onSuccess(project.subjects);
				} else {
					card.startLoading();
					$.loadSubjects({
						plicProjectId: project.plicProjectId,
						populateWithTemplate: true,
						onComplete: function(subjects) {
							card.stopLoading();
							project.subjects = subjects;
							onSuccess(project.subjects);
						},
						onError: function() {
							onError();
						}
					});
				}
			},
			setJobId: function(jobId) {
				this.jobId = jobId;
			},
			selectedPhotos: [],
			cardFunctions: {},
			// Needs to be higher then 30 so that scrollbar is always visible on default set
			photosPerPage: 40,

			// Some defaults, but expected to be changed by user
			selection: true,
			showSelectionOn: 'header',
			showSelection: true,
			preLoadImages: true,
			searchable: true,
			showSearchOn: 'header',
			loadFromPicker: true,
			photoType: 'candids',
			allWidePhotos: false,
			defaultUploadCategory: 'candid_category',
			allowRotateImage: false,
			constantFilters: [],
			getImage: function(photo, preview) {
				if(preview) {
					if(photo.cdn_url) {
						return photo.cdn_url;
					} else {
						return $.getPlicThumbnail(photo, {
							w: 300
						});
					}
				} else {
					return $.getPlicThumbnail(photo);
				}
			},
			getTitle: function(photo) {
				var title = photo.upload_file_name;
				if(title) {
					title = title.substr(0, title.lastIndexOf('.'));
				} else {
					title = '';
				}

				return title;
			},
			getCardPopup: function(card, photo) {
				if(this.getTitle) {
					var title = this.getTitle(photo);
					var tags = $.PhotoPickerUtils.getPhotoTags(photo);
					if(title) {
						if(tags.length) {
							return {
								title: title,
								content: tags.join(', ')
							};
						} else {
							return {
								content: title
							};
						}
					} else if(tags.length) {
						return {
							content: tags.join(', ')
						};
					}
				}

				return null;
			},
			getSearchCriteria: function(photo) {
				let title = (this.getTitle(photo) ?? '').toLowerCase();
				let tags = $.PhotoPickerUtils.getPhotoTags(photo);

				return title + ' ' + tags.join(', ');
			},

			editAlbumTags: function(button) {
				var currentAlbum = this.albumPicker.getCurrentCategory();
				
				var setTags = this.setAlbumTags;
				$.SettingsBuilderDialog([
					{
						id: 'tags',
						type: 'dropdown',
						multiple: true,
						options: setTags,
						value: currentAlbum.tags.join(',')
					}
				], {
					title: this.setAlbumTagsDescription || i18n.t('photoPicker.setAlbumTagsButton'),
					onSettingsApplied: function(settings) {
						var tags = currentAlbum.tags;
						var pickedTags = [];
						if(settings.tags) {
							pickedTags = settings.tags.split(',');
						}

						setTags.forEach(function(tag) {
							tags.removeItem(tag);
						});
						pickedTags.forEach(function(tag) {
							tags.push(tag);
						});

						$(button).addClass('loading');
						$.plicAPI({
							method: 'albums/' + currentAlbum.id,
							type: 'PUT',
							params: {
								album: {
									tags: currentAlbum.tags
								}
							},
							success: function() {
								currentAlbum.tags = tags;
								$(button).removeClass('loading');
							},
							error: function() {
								$.Alert('Error', 'Failed to edit album tags');
								$(button).removeClass('loading');
							}
						});
					}
				})
			},

			requireSelectionVisible: true,
			visibilityScrollRelativeTo: window.visibilityScrollRelativeTo || $('body'),
			cachedCards: {},

			showPageUsed: false
		}, options);

		if(me.header) {
			me.header = $('<h3 class="ui header photoPickerHeader">').text(me.header).appendTo(me);
		}

		if(me.backButton) {
			if(!me.headerButtons) {
				me.headerButtons = [];
			}

			me.headerButtons.unshift({
				name: i18n.t('misc.back'),
				color: 'primary',
				icon: 'left arrow',
				onClick: function() {
					if(typeof me.backButton == 'string') {
						$.managementTabs.tab('change tab', me.backButton);
					} else {
						window.returnToMainTab();
					}
				}
			});
		}

		if(me.editAlbums) {
			if(!me.headerButtons) {
				me.headerButtons = [];
			}

			me.headerButtons.push({
				popup: i18n.t('photoPicker.createAlbumButton'),
				icon: 'plus',
				color: '',
				requireAlbumsLoaded: true,
				onClick: function(button) {
					if($(button).hasClass('loading')) {
						return;
					}

					this.albumPicker.createNewAlbum(button, this.lastLoadAlbums);
				}
			});

			me.headerButtons.push({
				popup: i18n.t('photoPicker.deleteAlbumButton'),
				icon: 'remove',
				color: '',
				requireAlbum: true,
				requireMultipleAlbums: !options.allowNoAlbums,
				onClick: function(button) {
					if($(button).hasClass('loading')) {
						return;
					}

					this.albumPicker.deleteCurrentAlbum(button, this.currentPhotos);
				}
			});

			var editAlbumParams = $.extend({
				popup: i18n.t('photoPicker.editAlbumButton'),
				icon: 'edit',
				color: '',
				requireAlbum: true,
				onClick: function(button) {
					this.albumPicker.editCurrentAlbumName(button, this.lastLoadAlbums.prefix);
				}
			}, me.editAlbumParams);
			me.headerButtons.push(editAlbumParams);
		}
		if(me.setDefaultAlbum) {
			me.headerButtons.push({
				popup: i18n.t('photoPicker.setDefaultAlbumButton'),
				icon: 'linkify',
				color: '',
				requireAlbum: true,
				addClass: 'notDefaultAlbum',
				onClick: function(button) {
					this.albumPicker.setCurrentAlbumAsDefault(button);
				}
			});
		}
		if(me.setAlbumTags) {
			me.headerButtons.push({
				popup: me.setAlbumTagsDescription || i18n.t('photoPicker.setAlbumTagsButton'),
				icon: 'clipboard list',
				color: '',
				requireAlbum: true,
				onClick: function(button) {
					this.editAlbumTags(button);
				}
			});
		}

		if(me.uploadAlbum) {
			if(!me.headerButtons) {
				me.headerButtons = [];
			}

			var initialDropEvent = null;
			me.headerButtons.push({
				popup: i18n.t('photoPicker.uploadToAlbumButton'),
				icon: 'upload',
				requireAlbum: me.uploadAlbum === true,
				addClass: 'uploadToAlbumButton',
				onClick: function() {
					var album;
					if(this.albumPicker) {
						album = this.albumPicker.getCurrentCategory();
					} else {
						album = this.uploadAlbum;
					}

					if(album) {
						$.activeAlbumUpload = {
							id: album.id,
							name: album.name,
							tab: me.albumAlbumTab,
							onUploadComplete: function () {
								me.refreshPhotos();
							},
							requireAspectRatio: me.requireAspectRatio,
							addTags: me.addTags,
							defaultUploadCategory: me.defaultUploadCategory,
							initialDropEvent: initialDropEvent
						};
						$.managementTabs.tab('change tab', 'AlbumUploader.html');
					} else {
						var picker = null;
						if(this.albumPicker) {
							picker = {
								loadOptions: this.lastLoadAlbums,
								categories: this.albumPicker.getCurrentCategories(),
								currentCategory: this.albumPicker.getCurrentCategory()
							};
						}

						$.fireErrorReport(null, '$.PhotoPicker Upload Error', 'album to upload to is null', {
							uploadAlbum: this.uploadAlbum,
							picker: picker
						});
					}
					initialDropEvent = null;
				}
			});

			me.addEventListener('dragover', function(event) {
				event.preventDefault();
			});
			me.addEventListener('drop', function(event) {
				if(event.dataTransfer && event.dataTransfer.files.length === 0) {
					return;
				}

				// Grab the info out we need and pass directly since by the time it loads Chrome has removed the files from the list
				initialDropEvent = {
					dataTransfer: {
						files: event.dataTransfer.files,
						items: event.dataTransfer.items
					}
				};
				$(me).find('.uploadToAlbumButton.button').click();
				event.preventDefault();
			});
		}
		if(me.downloadAlbum) {
			if(!me.headerButtons) {
				me.headerButtons = [];
			}

			me.headerButtons.push({
				popup: i18n.t('photoPicker.downloadAlbumButton'),
				icon: 'download',
				requireAlbum: me.downloadAlbum === true,
				addClass: 'downloadAlbumButton',
				onClick: function() {
					let button = $(this);
					if(button.hasClass('loading')) {
						return;
					}

					let folderName = $.sanitizeFilename(me.albumPicker.getCurrentCategory().name);
					let photos = me.getAllPhotos().map(photo => ({
						name: photo.upload_file_name,
						path: 'photos/' + photo.id + '/original/' + photo.upload_file_name
					}));
					if(!photos.length) {
						$.Alert('Warning', 'No photos to download');
						return;
					}

					var url = $.AltDownloadDomain + 'api/v1/streamAlbumImages.php';
					var form = $('<form method="POST">')
						.attr('action', url)
						.attr('target', '_blank');

					$('<input type="hidden">')
						.attr('name', 'authToken')
						.attr('value', $.PlicToken)
						.appendTo(form);
					$('<input type="hidden">')
						.attr('name', 'zipFilename')
						.attr('value', folderName)
						.appendTo(form);

					var encodedJSON;
					if(window.btoa && window.pako) {
						// This is only necessary for users with crap upload speeds
						encodedJSON = window.btoa(window.pako.deflate(JSON.stringify(photos), {to: 'string'}));
					} else {
						encodedJSON = JSON.stringify(photos);
					}

					$('<input type="hidden">')
						.attr('name', 'photos')
						.attr('value', encodedJSON)
						.appendTo(form);
					
					$("body").append(form);
					form.submit();
					form.remove();
				}
			});
		}
		if(me.moveToAlbum) {
			if(!me.headerButtons) {
				me.headerButtons = [];
			}

			me.headerButtons.push({
				name: i18n.t('photoPicker.moveToAlbumButton'),
				icon: 'folder',
				addClass: 'dropdown moveToPicker',
				requireSelection: true,
				onCreate: function(buttonDiv, button) {
					me.moveToAlbumDropdown = new Vue({
						data: () => ({
							currentCategories: [],
							currentCategory: null
						}),
						methods: {
							setCategories(categories) {
								if(categories) {
									let copyCategories = $.extend(true, [], categories);
									this.getRecursiveCategories(copyCategories).forEach(category => {
										category.buttonId = 'B' + $.createRandomString(20);
									});

									this.currentCategories = copyCategories;
								} else {
									this.currentCategories = [];
								}
							},
							getRecursiveCategories: function(categories) {
								return categories.reduce((categories, category) => {
									if(category.subCategories) {
										categories.push(...[category, ...this.getRecursiveCategories(category.subCategories)]);
									} else {
										categories.push(category);
									}
					
									return categories;
								}, []);
							}
						},
						render: function(createElement) {
							return createElement('move-to-category-dropdown', {
								props: {
									items: this.currentCategories || [],
									selectedItem: this.currentCategory,
									dropdownClasses: 'requireSelection'
								},
								on: {
									'select-item': (album) => {
										if(!album) {
											return;
										}
										
										let chain = new $.ExecutionChain(function() {});
										me.eachSelected(function(card) {
											card.moveToAlbum(album.id, chain);
										});
										chain.done();
									}
								}
							});
						}
					}).$mount(buttonDiv[0]);

					button.div = $(me.moveToAlbumDropdown.$el);
					if(!me.selectedPhotos.length) {
						$(button.div).addClass('disabled');
					}
				}
			});
		}

		if(me.headerButtons) {
			for(let i = 0; i < me.headerButtons.length; i++) {
				me.addHeaderButton(me.headerButtons[i]);
			}
		}

		if(!me.selection) {
			me.showSelection = false;
		}
		if(me.showSelection) {
			if(me.header) {
				me.header.addClass('left floated');
			}

			me.selectionHeader = $('<h3 class="ui right floated header"></h3>');
			me.selectAllCheckbox = $('<div style="margin: 0 1.4em;" class="ui checkbox"><input type="checkbox" name="selectAllBox" tabindex="0" class="hidden"><label>' + i18n.t('misc.selectAll') + '</label></div>').checkbox({
				fireOnInit: false,
				onChecked: function() {
					me.selectedPhotos = $.merge([], me.currentPhotos);

					me.picker.find('.card').addClass('blue').find('.corner.label').removeClass('hidden');
					me.updateSelectedCount();
				},
				onUnchecked: function() {
					me.selectedPhotos = [];

					me.picker.find('.card').removeClass('blue').find('.corner.label').addClass('hidden');
					me.updateSelectedCount();
				}
			}).appendTo(me.selectionHeader);
			me.selectedCountLabel = $('<div class="ui teal tag label candidsSelected">' + i18n.t('misc.selected', { count: 0 }) + '</div>').appendTo(me.selectionHeader);
			
			if(me.showSelectionOn == 'header') {
				$(me.selectionHeader).css('margin-top', '0.5em').appendTo(me);
			}
		}
		
		if(me.searchable) {
			me.searchBox = $('<div class="ui icon input" style="float: right; text-align: right;">');
			me.searchInput = $('<input type="search" class="" placeholder="Search..." style="width: auto;">').appendTo(me.searchBox);
			me.searchIcon = $('<i class="search icon">').appendTo(me.searchBox);
			me.searchStopIcon = $('<i class="close link icon" style="display: none; cursor: pointer;">').appendTo(me.searchBox);

			me.searchInput.on('keyup', function(e) {
				// On escape, cancel current search
				var value = this.value.toLowerCase();
				if (e.keyCode == 27 || !value) {
					me.stopSearching();
					return;
				}

				// Don't bother if the same as last query or if length is too small
				if($(this).data('lastQuery') == value || value.length <= 1) {
					return;
				}
				$(this).data('lastQuery', value);

				me.search(value);
			});
			me.searchStopIcon.click(function() {
				me.stopSearching();
			});

			if(me.showSearchOn === 'header') {
				me.searchBox.appendTo(me);
			}
		}

		me.tagPicker = $('<div class="photoTagPicker">').appendTo(me);

		me.picker = $('<div class="photoPicker" style="margin-top: 0.5em">').appendTo($(me));
		if(me.headerButtons || me.searchable) {
			me.tagPicker.css('margin-top', '1em');
		}

		if(me.showSelection && me.showSelectionOn == 'tags') {
			$(me.tagPicker).append(me.selectionHeader);
		}
		if(me.searchBox && me.showSearchOn === 'tags') {
			me.searchBox.css('marginTop', '-0.5em').appendTo(me.tagPicker);
		}
		if(me.toggleNotInUse) {
			var filterNotInUse = function(photo) {
				return !photo.used || !photo.used.count;
			};

			let toggleBox = me.toggleNotInUseBox = $('<div class="ui toggle checkbox" style="margin-left: 0.75em; margin-right: 0.75em;" data-tooltip="' + i18n.t('photoPicker.toggleNotInUseTooltip') + '" data-position="right center"> <input type="checkbox" name="toggleNotInUse"><label>' + i18n.t('photoPicker.toggleNotInUseLabel') + '</label></div>');
			toggleBox.checkbox({
				onChecked: function() {
					me.addConstantFilter(filterNotInUse);
				},
				onUnchecked: function() {
					me.removeConstantFilter(filterNotInUse);
				}
			});

			$(me.tagPicker).append(me.toggleNotInUseBox);
		}

		var emptyPhotosMessage = $('<div class="ui warning message">');
		if(me.emptyPhotosMessage) {
			emptyPhotosMessage.text(me.emptyPhotosMessage);
		} else {
			emptyPhotosMessage.text(i18n.t('photoPicker.noPhotosWarning', {
				photoType: me.photoType
			}));
		}
		me.emptyPhotosMessage = emptyPhotosMessage.hide().appendTo(me);

		var emptyAlbumsMessage = $('<div class="ui warning message">');
		if(me.emptyAlbumsMessage) {
			emptyAlbumsMessage.text(me.emptyAlbumsMessage);
		} else {
			emptyAlbumsMessage.text(i18n.t('photoPicker.noAlbumsWarning'));
		}
		me.emptyAlbumsMessage = emptyAlbumsMessage.hide().appendTo(me);

		var errorLoadingMessage = $('<div class="ui error message">');
		if(me.errorLoadingMessage) {
			errorLoadingMessage.text(me.errorLoadingMessage);
		} else {
			errorLoadingMessage.text('Failed to load album');
		}
		me.errorLoadingMessage = errorLoadingMessage.hide().appendTo(me);

		me.loader = $('<div class="ui inverted dimmer"><div class="ui text loader">' + i18n.t('misc.loading') + '</div></div>').appendTo($(me));
		$(me).addClass('photoPickerWrapper');
		if(me.photos) {
			$(me).show();
			me.showPhotos(me.photos);
		}

		return me;
	};
});

$.PhotoPickerDialog = function(options) {
	var modal = $('<div class="ui modal">')[0];
	$(modal).append('<i class="close icon"/>');

	$.extend(modal, {
		initPhotoPicker: function() {
			$(this.photoPicker).PhotoPicker($.extend(options.photoPicker, {
				allWidePhotos: true,
				onImageDoneLoading: function() {
					$(modal).modal('refresh');
				}
			}));
		},
		show: function() {
			$(this).modal('show');
		}
	}, options);

	var header = $('<div class="header">');
	header.text(options.title);
	if(options.icon) {
		header.prepend('<i class="icon ' + options.icon + '">')
	}
	$(modal).append(header);

	var content = $('<div class="content" style="min-height: 10em">');
	modal.photoPicker = $('<div class="ui">').appendTo(content)[0];
	modal.content = content;
	$(modal).append(content);

	if(options.actions) {
		var actions = $('<div class="actions">');

		options.actions.forEach(function(action) {
			var button = $('<div class="ui button">').text(action.title);
			if(action.classes) {
				button.addClass(action.classes);
			}

			actions.append(button);
		});

		$(modal).append(actions);
	}

	$(modal).modal({
		onVisible: function() {
			modal.initPhotoPicker();

			if(options.loadAjax) {
				modal.photoPicker.loadPhotos({
					ajax: options.loadAjax,
					getData: options.loadAjaxGetData
				});
			}

			if(modal.onVisible) {
				modal.onVisible();
			}
		},
		onHidden: function() {
			$(this).remove();
		},
		onApprove: function() {
			return modal.onApprove();
		}
	});
	return modal;
};

$.PhotoPickerUtils = {
	getPhotoTags: function(photo) {
		if(photo.tags && photo.tags.length && photo.tags.filter && photo.tags.join) {
			return photo.tags.filter(tag => isUserTag(tag)).map(function(tag) {
				return tag.replace(/\n/g, '');
			});
		} else {
			return [];
		}
	},
	loadTagEditor: function(settings) {
		if(settings.startLoading) {
			settings.startLoading();
		}
		settings.load(settings, function() {
			if(settings.stopLoading) {
				settings.stopLoading();
			}
			$.PhotoPickerUtils.openTagEditor(settings);
		}, function() {
			if(settings.stopLoading) {
				settings.stopLoading();
			}
			$.Alert('Failed to load tags');
		});
	},
	openTagEditor: function(settings) {
		var settingFields = [
			{
				type: 'section',
				perLine: 3,
				settings: [
					{
						id: 'upload_file_name',
						type: 'text',
						description: i18n.t('misc.filename'),
						value: settings.photo.upload_file_name,
						readOnly: true
					},
					{
						id: 'width',
						type: 'text',
						description: i18n.t('misc.width'),
						value: settings.photo.width,
						readOnly: true
					},
					{
						id: 'height',
						type: 'text',
						description: i18n.t('misc.height'),
						value: settings.photo.height,
						readOnly: true
					},
					{
						id: 'upload_file_size',
						type: 'text',
						description: i18n.t('misc.filesize'),
						value: $.formatBytes(settings.photo.upload_file_size),
						readOnly: true
					},
					{
						id: 'upload_verified_at',
						type: 'text',
						description: i18n.t('misc.uploadedAt'),
						value: settings.photo.upload_verified_at ? moment(settings.photo.upload_verified_at).format('MMMM Do YYYY h:mm A') : 'Never',
						readOnly: true
					}
				]
			}
		];

		if(settings.photo.created_by_album_import_id && settings.albumImports) {
			var albumImport = settings.albumImports.find(function(albumImport) {
				return albumImport.id === settings.photo.created_by_album_import_id;
			});
			if(albumImport) {
				var user = (settings.users || []).find(function(user) {
					return user.id === albumImport.user_id
				});

				// No import info just means this is an old upload.  No user means this was uploaded through guest uploader or other mechanism
				var userName = 'Anonymous user';
				if(user) {
					userName = user.name + ' (' + user.email + ')';
				}

				settingFields[0].settings.push({
					id: 'uploaded_by_user',
					type: 'text',
					description: i18n.t('misc.uploadedBy'),
					value: userName,
					readOnly: true
				});
			}
		}
		
		if(settings.albums) {
			settings.albums.forEach(album => {
				fixAlbumName(album);
			});
			settingFields[0].settings.push({
				id: 'in_album',
				type: 'text',
				description: i18n.t('photoPicker.album'),
				value: settings.albums.map(album => album.name).join(', '),
				readOnly: true
			});
		}

		var tagFields = [
			{
				id: 'tags',
				type: 'dropdown',
				description: i18n.t('photoPicker.descriptionTags'),
				placeholder: 'e.g. Band Practice, Prom...',
				value: $.PhotoPickerUtils.getPhotoTags(settings.photo).join(','),
				multiple: true,
				allowAdditions: true,
				copyButton: {
					onClick: function(field, tags) {
						navigator.clipboard.writeText(tags.join(', '));

						$(field).find('input')[1].focus();
					}
				}
			}
		];
		if(settings.subjects) {
			for(let i = 0; i < settings.subjects.length; i++) {
				let subject = settings.subjects[i];
				subject.name = subject['First Name'] + ' ' + subject['Last Name'];
			}
			settings.subjects.caseInsensitiveSort('name');

			tagFields.push({
				id: 'subjects',
				type: 'dropdown',
				description: i18n.t('misc.subjects'),
				placeholder: 'e.g. John Smith',
				options: settings.subjects,
				multiple: true,
				search: true,
				valueAjax: $.getPlicAPI({
					method: 'projects/' + settings.plicProjectId + '/subjects',
					params: {
						filter: {
							photo_id: settings.photo.id
						},
						include_photos: false
					},
					type: 'GET'
				}),
				valueLoadSubParam: 'subjects',
				onAfterLoadValues: function(subjects) {
					var field = this;
					subjects.forEach(function(subject) {
						// We don't want to allow users to change the main subject photo from here
						if(settings.photo.id === subject.primary_photo_id) {
							$(field).addClass('disabled');

							if(!$(field).next().hasClass('message')) {
								$(field).after('<div class="ui visible warning message">You cannot edit the tags of the primary subject image</div>');
							}
						}
					});
				},
				copyButton: {
					onClick: function(field, ids, subjects) {
						let names = subjects.map(s => s.name);
						navigator.clipboard.writeText(names.join(', '));
					}
				}
			});
		}

		settingFields.push({
			type: 'section',
			settings: tagFields
		});

		var modal = $.ImageSettingsDialog(settingFields, {
			title: i18n.t('photoPicker.imageInfoTitle'),
			icon: 'tag',
			image: {
				url: settings.imageUrl
			},
			updateObject: settings.photo,
			modal: settings.modal,
			wrapperDim: window.innerHeight / 3,
			onSettingsApplied: function(newSettings, changes) {
				var addSubjects = [], removeSubjects = [], addTags = [], removeTags = [];
				for(let i = 0; i < changes.length; i++) {
					var change = changes[i];
					var values = change.value.split(',');

					if(change.id == 'subjects') {
						if (change.type == 'add') {
							addSubjects = values;
						} else if (change.type == 'remove') {
							removeSubjects = values;
						}
					} else if(change.id == 'tags') {
						if (change.type == 'add') {
							addTags = values;
						} else if (change.type == 'remove') {
							removeTags = values;
						}
					}
				}

				if($.isInit(newSettings.tags)) {
					if(newSettings.tags) {
						settings.photo.tags = newSettings.tags.split(',');
					} else {
						settings.photo.tags = [];
					}

					if(settings.updateTags) {
						settings.updateTags();
					}
				}
				for(let i = 0; i < addSubjects.length; i++) {
					var addSubjectId = addSubjects[i];

					for(let j = 0; j < settings.subjects.length; j++) {
						let subject = settings.subjects[j];
						if(subject.id == addSubjectId && subject.photos) {
							let oldIndex = subject.photos.indexOf(settings.photo);
							if(oldIndex === -1) {
								subject.photos.push(settings.photo);
								$.PhotoPickerUtils.updateSubjectCardIfExists(subject);
							}
							break;
						}
					}
				}
				for(let i = 0; i < removeSubjects.length; i++) {
					var removeSubjectId = removeSubjects[i];

					for(let j = 0; j < settings.subjects.length; j++) {
						let subject = settings.subjects[j];
						if(subject.id == removeSubjectId) {
							let oldIndex = subject.photos.indexOfMatch(settings.photo, 'id');
							if(oldIndex != -1 && subject.photos) {
								subject.photos.splice(oldIndex, 1);
								$.PhotoPickerUtils.updateSubjectCardIfExists(subject);
							}
							break;
						}
					}
				}

				if(settings.startLoading) {
					settings.startLoading();
				}
				$.PhotoPickerUtils.syncSubjectTags({
					photo: settings.photo,
					addSubjectIds: addSubjects,
					removeSubjectIds: removeSubjects,
					addTags: addTags,
					removeTags: removeTags,
					onSuccess: function() {
						if(modal.currentVersionImage && modal.rotations !== 0) {
							$.PhotoPickerUtils.saveNewPhotoVersion(settings, modal.currentVersionImage);
						} else if(settings.stopLoading) {
							settings.stopLoading();
						}
					},
					onError: function() {
						$.Alert('Failed to save tags');
						if(settings.stopLoading) {
							settings.stopLoading();
						}
					}
				});
			}
		});

		if(window.rotateImage && settings.updatePhoto && settings.allowRotateImage) {
			modal.rotations = 0;
			modal.rotateImage = function(rotateRight) {
				modal.loader.addClass('active');
	
				var url;
				if(this.currentVersionImage) {
					url = URL.createObjectURL(this.currentVersionImage);
				} else {
					url = $.getPlicDownloadUrl(settings.photo);
				}
	
				modal[0].currentRotationPromise = window.rotateImage(url, rotateRight).then(function(rotatedImage) {
					modal.loader.removeClass('active');
					modal.find('img').attr('src', URL.createObjectURL(rotatedImage))
					modal.currentVersionImage = rotatedImage;
					
					if(rotateRight) {
						modal.rotations++;
					} else {
						modal.rotations--;
					}
				}).catch(function(e) {
					modal.loader.removeClass('active');
					$.Alert('Error', 'Failed to rotate image: ' + e.message);
				});
			};

			var buttonBar = $('<div class="ui rotateButtons" style="margin-bottom: 1em; text-align: center;"></div>').insertBefore($(modal).find('.settings-builder'));
			$('<div class="ui icon button" data-tooltip="' + i18n.t('photoPicker.rotateLeft') + '"><i class="undo icon"></i></div>').click(function() {
				modal.rotateImage(false);
			}).appendTo(buttonBar);
			$('<div class="ui icon button" data-tooltip="' + i18n.t('photoPicker.rotateRight') + '"><i class="repeat icon"></i></div>').click(function() {
				modal.rotateImage(true);
			}).appendTo(buttonBar);

			modal.loader = $('<div class="ui inverted dimmer"><div class="ui text loader"></div></div>').appendTo(modal.find('.content'));
		}
	},
	saveNewPhotoVersion(settings, newVersionImage) {
		$.plicAPI({
			method: 'photos/' + settings.photo.id + '/reupload',
			success: function(data) {
				var formData = new FormData();
				var post = data.presigned_post;

				for(var id in post.fields) {
					formData.set(id, post.fields[id]);
				}
				formData.append('file', newVersionImage);
				
				$.proxyAjax({
					url: post.url,
					data: formData,
					type: 'POST',
					processData: false,
					contentType: false,
					success: function() {
						$.proxyAjax($.getPlicAPI({
							method: 'photos/' + settings.photo.id + '/verify',
							type: 'PATCH',
							success: function(data) {
								var newPhoto = data.photo;
								newPhoto.width = newVersionImage.imageWidth;
								newPhoto.height = newVersionImage.imageHeight;
								settings.updatePhoto(newPhoto);

								$.proxyAjax({
									url: 'ajax/updatePhotoDimensions.php',
									data: {
										photoId: settings.photo.id,
										newWidth: newVersionImage.imageWidth,
										newHeight: newVersionImage.imageHeight,
										projectId: settings.plicProjectId
									}
								});
							},
							error: function() {
								$.Alert('Error', 'Failed to verify new photo version');
								if(settings.stopLoading) {
									settings.stopLoading();
								}
							}
						}));
					},
					error: function() {
						$.Alert('Error', 'Failed to POST new version to S3');

						if(settings.stopLoading) {
							settings.stopLoading();
						}
					}
				});
			},
			error: function() {
				$.Alert('Error', 'Failed to create reupload record');

				if(settings.stopLoading) {
					settings.stopLoading();
				}
			}
		});
	},
	updateSubjectCardIfExists: function(subject) {
		let card = $('#subject' + subject.id + '.card');
		if(card.length) {
			card[0].updateTaggedPhotosButton();
		}
	},
	syncSubjectTags: function(settings) {
		var chain = new $.ExecutionChain(function() {
			if(this.errorCount === 0) {
				settings.onSuccess();
			} else {
				settings.onError();
			}
		});

		for(let i = 0; i < settings.addSubjectIds.length; i++) {
			let subjectId = settings.addSubjectIds[i];
			chain.add($.getPlicAPI({
				method: 'subject-photo-assignments',
				params: {
					subject_photo_assignment: {
						subject_id: subjectId,
						photo_id: settings.photo.id
					}
				},
				type: 'POST'
			}));
		}

		for(let i = 0; i < settings.removeSubjectIds.length; i++) {
			let subjectId = settings.removeSubjectIds[i];
			chain.add($.getPlicAPI({
				method: 'subject-photo-assignments',
				params: {
					subject_id: subjectId,
					photo_id: settings.photo.id
				},
				type: 'DELETE'
			}));
		}

		if(settings.addTags.length || settings.removeTags.length) {
			chain.add($.getPlicAPI({
				method: 'photos/' + settings.photo.id,
				params: {
					tags_to_add: settings.addTags,
					tags_to_remove: settings.removeTags
				},
				type: 'PATCH'
			}));
		}

		chain.done();
	}
};