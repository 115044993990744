<template>
	<div class="header" v-if="sortedBatches.length > 0">
		<v-card class="batch-wrapper" ref="card" v-horizontal-scroll v-if="sortedBatches.length > 0">
			<batch-header-button v-for="(batch, batchIndex) in sortedBatches" :key="batchIndex" :batch="batch" :editable="editable" @clicked="clickedBatch" @rename-batch="renameBatch" />
		</v-card>
		
		<v-btn
			tile
			class="add-button proof-page-button batch-button classButton"
			color="primary"
			@click="createNewBatch"
			v-if="canCreateNewBatch">
			<v-icon left>add</v-icon>

			<span>New</span>
		</v-btn>
	</div>
	<v-alert class="empty-batches" type="warning" dense v-else>
		Classes (batches) have not yet been imported
	</v-alert>
</template>

<script>
import BatchHeaderButton from './BatchHeaderButton';
import '../../../../PlicCanvas/vue/directives/horizontal-scroll';

export default {
	props: ['batches', 'editable', 'showWaitingReviewFirst', 'canCreateNewBatch', 'loadAlbumOptions'],
	computed: {
		sortedBatches() {
			if(this.showWaitingReviewFirst) {
				let atFront = this.batches.filter(batch => batch.atFront);
				let waitingReview = this.batches.filter(batch => (batch.approved === null || batch.approved === undefined) && !batch.atFront);
				let approved = this.batches.filter(batch => (batch.approved === true || batch.approved === false) && !batch.atFront);

				atFront.batchNameSort();
				waitingReview.batchNameSort();
				approved.batchNameSort();

				return [
					...atFront,
					...waitingReview,
					...approved
				];
			} else {
				let visible = this.batches.filter(batch => batch.visible !== false);
				let atFront = visible.filter(batch => batch.atFront);
				let everythingElse = visible.filter(batch => !batch.atFront);
				let hidden = this.batches.filter(batch => batch.visible === false);

				atFront.batchNameSort();
				everythingElse.batchNameSort();
				hidden.batchNameSort();

				return [
					...atFront,
					...everythingElse,
					...hidden
				];
			}
		}
	},
	methods: {
		clickedBatch(batch) {
			this.$emit('clicked-batch', batch);
		},
		renameBatch(batch) {
			$.EditNameModal('Edit Name', 'Name', batch.name, (newName) => {
				this.$emit('batch-renamed', batch, newName);
			});
		},
		createNewBatch() {
			let modal = $('<div class="ui large modal v-application v-application--is-ltr v-application-dialog-content" style="text-align: left"><i class="close icon"></i></div>')[0];
			let content = $('<div class="content"><div id="createNewBatchContent"></div></div>');

			let vue = null;
			$.extend(modal, {
				init: () => {
					vue = new Vue({
						data: {
							data: {
								name: '',
								photo: null
							},
							loadAlbumOptions: this.loadAlbumOptions
						},
						template: '<create-new-proof-batch-dialog :data="data" :load-album-options="loadAlbumOptions" />',
						watch: {
							data: {
								deep: true,
								handler: function() {
									this.checkIsValid();
								}
							}
						},
						methods: {
							checkIsValid: function() {
								let error = null;
								if(!this.data.name.trim()) {
									error = 'Name is required';
								} else if(!this.data.photo) {
									error = 'Photo is required';
								}

								modal.setSaveError(error);
							}
						},
						vuetify: window.Vuetify
					}).$mount('#createNewBatchContent');
				},
				show: function() {
					$(this).modal('show');
				},
				setSaveError: function(error) {
					if(!error) {
						$(this).find('.actions').removeAttr('data-tooltip')
						$(this).find('.primary.dialogButton').removeClass('disabled');
					} else {
						$(this).find('.actions').attr('data-tooltip', error)
						$(this).find('.primary.dialogButton').addClass('disabled');
					}
				}
			});

			$(modal).append($('<div class="header">').text('Create New Batch').prepend('<i class="plus icon"></i>'))
				.append(content)
				.append('<div class="actions" data-position="top right"><div class="ui deny button dialogButton">Cancel</div><div class="ui approve primary button disabled dialogButton">OK</div></div>')
				.modal({
					onApprove: () => {
						if(vue) {
							this.$emit('create-new-batch', vue.data);
						} else {
							return false;
						}
					},
					onShow: function() {
						modal.init();
					},
					onHidden: function() {
						$(this).remove();
					},
					observeChanges: true
				});

			modal.show();
		}
	},
	components: {
		BatchHeaderButton
	}
};
</script>

<style scoped>
.header {
	position: relative;
	min-height: 2em;

	display: flex;
}

.batch-wrapper {
	height: 100%;
	overflow-y: hidden;
	overflow-x: scroll;
	display: flex;
	flex-direction: row;

	flex-grow: 1;
}

.add-button {
	align-self: center;
	margin-left: 0.5em;
}

.batch-wrapper::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
.batch-wrapper::-webkit-scrollbar-track {
    background: #EEE; 
}

/* Handle */
.batch-wrapper::-webkit-scrollbar-thumb {
    background: #BBB; 
}

/* Handle on hover */
.batch-wrapper::-webkit-scrollbar-thumb:hover {
	background: #06F;
}

.loader-overlay {
	position: absolute;
	width: 100%;
}

.empty-batches {
	margin-bottom: 0;
}
.empty-batches:deep(.v-alert__wrapper) {
	justify-content: center;
}
.empty-batches:deep(.v-alert__content) {
	flex-grow: 0;
}
</style>