import { BASE_COLOR, BRAND_BASE_COLOR } from './colors';

export const COMMON_COLORS = {
	primary: BRAND_BASE_COLOR.highVolume,
	success: BASE_COLOR.emerald['700'],
	error: BASE_COLOR.red['600'],
	warning: BASE_COLOR.amber['500'],
	anchor: BASE_COLOR.futureBlue['600'],
	info: BASE_COLOR.cyan['600'],

	headerPrimary: BASE_COLOR.zink['900'],
	headerSecondary: BASE_COLOR.zink['800'],
	
	blue: BASE_COLOR.futureBlue[600]
}