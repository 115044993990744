/* eslint-disable */
// Copied from https://bitbucket.org/imagequix/design-system/src/main/src/colors.ts

// ------------------------------------ Base Colors ------------------------------------

export const BASE_COLOR = {
	/**
	 * Sepia - Charts
	 */
	sepia: {
	  '50': '#fbfaf3',
	  '100': '#f7f6ee',
	  '200': '#eae8da',
	  '300': '#d8d6c8',
	  '400': '#aaa794',
	  '500': '#797663',
	  '600': '#5a5744',
	  '700': '#474431',
	  '800': '#2c2916',
	  '900': '#201d0a',
	  '950': '#181506',
	},
  
	/**
	 * Zink - Used for input/table labels and plain text
	 */
	zink: {
	  '50': '#fafafa',
	  '100': '#f4f4f5',
	  '200': '#e4e4e7',
	  '300': '#d4d4d8',
	  '400': '#a1a1aa',
	  '500': '#71717a',
	  '600': '#52525b',
	  '700': '#3f3f46',
	  '800': '#27272a',
	  '900': '#18181b',
	  '950': '#09090b',
	},
  
	/**
	 * Pink - Charts
	 */
	pink: {
	  '50': '#fdf4ff',
	  '100': '#fce7f3',
	  '200': '#fbcfe8',
	  '300': '#f9a8d4',
	  '400': '#f472b6',
	  '500': '#ec4899',
	  '600': '#db2777',
	  '700': '#be185d',
	  '800': '#9d174d',
	  '900': '#831843',
	  '950': '#500724',
	},
  
	/**
	 * Fuchsia - Charts
	 */
	fuchsia: {
	  '50': '#fdf4ff',
	  '100': '#fae8ff',
	  '200': '#f5d0fe',
	  '300': '#f0abfc',
	  '400': '#e879f9',
	  '500': '#d946ef',
	  '600': '#c026d3',
	  '700': '#a21caf',
	  '800': '#86198f',
	  '900': '#701a75',
	  '950': '#4a044e',
	},
  
	/**
	 * Vision Purple - Used for AI link/indicator
	 */
	visionPurple: {
	  '50': '#faf5ff',
	  '100': '#f3e8ff',
	  '200': '#e8d5ff',
	  '300': '#d7b5ff',
	  '400': '#bd85ff',
	  '500': '#a458ff',
	  '600': '#873be2',
	  '700': '#7327ce',
	  '800': '#691dc4',
	  '900': '#5510a8',
	  '950': '#350075',
	},
  
	/**
	 * Indigo - Charts
	 */
	indigo: {
	  '50': '#eef2ff',
	  '100': '#e0e7ff',
	  '200': '#c7d2fe',
	  '300': '#a5b4fc',
	  '400': '#818cf8',
	  '500': '#6366f1',
	  '600': '#4f46e5',
	  '700': '#4338ca',
	  '800': '#3730a3',
	  '900': '#312e81',
	  '950': '#1e1b4b',
	},
  
	/**
	 * Future Blue - Used for Workflow, HV link, selected row
	 */
	futureBlue: {
	  '50': '#f1f5ff',
	  '100': '#dfe8ff',
	  '200': '#c7d7ff',
	  '300': '#a3bdff',
	  '400': '#739aff',
	  '500': '#4d7fff',
	  '600': '#1555fa',
	  '700': '#0e47dc',
	  '800': '#0337bf',
	  '900': '#002da3',
	  '950': '#001b61',
	},
  
	/**
	 * Sky - Charts
	 */
	sky: {
	  '50': '#f0f9ff',
	  '100': '#e0f2fe',
	  '200': '#bae6fd',
	  '300': '#7dd3fc',
	  '400': '#38bdf8',
	  '500': '#0ea5e9',
	  '600': '#0284c7',
	  '700': '#0369a1',
	  '800': '#075985',
	  '900': '#0c4a6e',
	  '950': '#082f49',
	},
  
	/**
	 * Cyan - Charts
	 */
	cyan: {
	  '50': '#ecfeff',
	  '100': '#cffafe',
	  '200': '#a5f3fc',
	  '300': '#67e8f9',
	  '400': '#22d3ee',
	  '500': '#06b6d4',
	  '600': '#0891b2',
	  '700': '#0e7490',
	  '800': '#155e75',
	  '900': '#164e63',
	  '950': '#083344',
	},
  
	/**
	 * Teal - Charts
	 */
	teal: {
	  '50': '#f0fdfa',
	  '100': '#ccfbf1',
	  '200': '#99f6e4',
	  '300': '#5eead4',
	  '400': '#2dd4bf',
	  '500': '#14b8a6',
	  '600': '#0d9488',
	  '700': '#0f766e',
	  '800': '#115e59',
	  '900': '#134e4a',
	  '950': '#042f2e',
	},
  
	/**
	 * Emerald - Used as positive color
	 */
	emerald: {
	  '50': '#ecfdf5',
	  '100': '#d1fae5',
	  '200': '#a7f3d0',
	  '300': '#6ee7b7',
	  '400': '#34d399',
	  '500': '#10b981',
	  '600': '#059669',
	  '700': '#047857',
	  '800': '#065f46',
	  '900': '#064e3b',
	  '950': '#022c22',
	},
  
	/**
	 * Connect Green - Used for CRM
	 */
	connectGreen: {
	  '50': '#e7fdf3',
	  '100': '#d4fce9',
	  '200': '#9ff9cd',
	  '300': '#6bf5b1',
	  '400': '#0fe57c',
	  '500': '#0dc66b',
	  '600': '#0b9e56',
	  '700': '#097c43',
	  '800': '#066135',
	  '900': '#054d2a',
	  '950': '#032b17',
	},
  
	/**
	 * Lime - Charts
	 */
	lime: {
	  '50': '#f7fee7',
	  '100': '#ecfccb',
	  '200': '#d9f99d',
	  '300': '#bef264',
	  '400': '#a3e635',
	  '500': '#84cc16',
	  '600': '#65a30d',
	  '700': '#4d7c0f',
	  '800': '#3f6212',
	  '900': '#365314',
	  '950': '#1a2e05',
	},
  
	/**
	 * Yellow - Used for caution or notifications
	 */
	yellow: {
	  '50': '#fefce8',
	  '100': '#fef9c3',
	  '200': '#fef08a',
	  '300': '#fde047',
	  '400': '#facc15',
	  '500': '#eab308',
	  '600': '#ca8a04',
	  '700': '#a16207',
	  '800': '#854d0e',
	  '900': '#713f12',
	  '950': '#422006',
	},
  
	/**
	 * Amber - Charts
	 */
	amber: {
	  '50': '#fffbeb',
	  '100': '#fef3c7',
	  '200': '#fde68a',
	  '300': '#fcd34d',
	  '400': '#fbbf24',
	  '500': '#f59e0b',
	  '600': '#d97706',
	  '700': '#b45309',
	  '800': '#92400e',
	  '900': '#78350f',
	  '950': '#451a03',
	},
  
	/**
	 * Moment Orange
	 */
	momentOrange: {
	  '50': '#fff6f1',
	  '100': '#ffebe0',
	  '200': '#ffd3bb',
	  '300': '#ffb58c',
	  '400': '#ff8d4e',
	  '500': '#ff7a30',
	  '600': '#f35802',
	  '700': '#bf4400',
	  '800': '#9a3700',
	  '900': '#812e00',
	  '950': '#401700',
	},
  
	/**
	 * Red - Used as negative color
	 */
	red: {
	  '50': '#fef2f2',
	  '100': '#fee2e2',
	  '200': '#fecaca',
	  '300': '#fca5a5',
	  '400': '#f87171',
	  '500': '#ef4444',
	  '600': '#dc2626',
	  '700': '#b91c1c',
	  '800': '#991b1b',
	  '900': '#7f1d1d',
	  '950': '#450a0a',
	},
  
	/**
	 * Rose - Charts
	 */
	rose: {
	  '50': '#fff1f2',
	  '100': '#ffe4e6',
	  '200': '#fecdd3',
	  '300': '#fda4af',
	  '400': '#fb7185',
	  '500': '#f43f5e',
	  '600': '#e11d48',
	  '700': '#be123c',
	  '800': '#9f1239',
	  '900': '#881337',
	  '950': '#4c0519',
	},
  };
  
  // ------------------------------------ Semantic Mappings ------------------------------------
  
  export const NEUTRAL_COLOR = {
	inputLabel: BASE_COLOR.zink[500],
	plainText: BASE_COLOR.zink[900],
  };
  
  export const BRAND_BASE_COLOR = {
	crm: BASE_COLOR.connectGreen[700],
	highVolume: BASE_COLOR.futureBlue[600],
	intelligence: BASE_COLOR.visionPurple[600],
	moments: BASE_COLOR.momentOrange[700],
  };
  