import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { COMMON_COLORS } from '../../PlicCanvas/css/common/colors-extras';

Vue.use(Vuetify);


export default new Vuetify({
	icons: {
		iconfont: 'md',
	},
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				...COMMON_COLORS
			}
		}
	}
});