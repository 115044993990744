<template>
	<div class="rebrand-banner" :style="{ display: visible ? '' : 'none'}">
		<div>
			{{ message }}  <a href="https://captura.io/fresh-product-names-for-the-future-of-captura" target="_blank">Learn more <v-icon>arrow_forward</v-icon></a>
		</div>

		<v-btn icon class="close-button" @click="close" v-if="saveClosing">
			<v-icon>close</v-icon>
		</v-btn>
	</div>
</template>

<script>
import localforage from 'localforage';

const cacheKey = 'rebrand-banner-closed';
export default {
	props: ['message', 'saveClosing'],
	data: () => ({
		visible: false
	}),
	methods: {
		close() {
			this.visible = false;

			localforage.setItem(cacheKey, true);
		}
	},
	mounted() {
		if(this.saveClosing) {
			localforage.getItem(cacheKey).then(response => {
				this.visible = !response;
			});
		} else {
			this.visible = true;
		}
	}
};
</script>

<style scoped>
.rebrand-banner {
	width: 100%;
	height: 48px;
	flex-shrink: 0;

	color: white;
	background-color: var(--v-headerPrimary-base);
	font-size: 16px;
	
	display: flex;
	align-items: center;
	justify-content: center;
}
.rebrand-banner a {
	color: white;
	text-decoration: underline;
}
.rebrand-banner a > .v-icon {
	color: white;
	font-size: 16px;
	text-decoration: underline;
	vertical-align: initial;
	margin-left: -2px;
}

.close-button, .close-button.v-btn {
	color: white;

	position: absolute;
	right: 1em;
}
</style>