<template>
	<v-layout justify-center class="login-page">
		<v-flex xs12 sm8 md4 class="wrapper">
			<v-btn rounded class="back-button" @click="$router.back()">
				<v-icon left>
					arrow_back
				</v-icon>
				Back
			</v-btn>
			<img class="app-logo" :src="displayedLogoUrl">

			<v-card>
				<h2 class="header">Forgot Password</h2>
				<div>Enter the username you are trying to reset for, and we will send instructions to the email address associated to that user.</div>

				<v-card-text class="card-content" v-if="!loading">
					<v-form @submit.prevent="resetPassword">
						<v-text-field
							label="Username"
							type="text"
							v-model="email"
							outlined
							:error-messages="emailErrors"
							@keyup.enter.native="resetPassword"
							ref="email"
							id="username"
						></v-text-field>
					</v-form>

					<v-alert type="warning">
						This password reset applies to Captura School Portal, Yearbook, and Product Designer.
					</v-alert>

					<v-btn class="submit-button" color="primary" @click="resetPassword" rounded>Submit</v-btn>

					<div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
				</v-card-text>

				<v-progress-circular class="loading" indeterminate v-if="loading"></v-progress-circular>
			</v-card>

			<v-alert
				type="success"
				:value="!!successMessage"
				transition="scale-transition"
				class="bottom-message">

				{{ successMessage }}
			</v-alert>
		</v-flex>
	</v-layout>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { plicAxios, logAxiosError } from '../utils/axios';

export default {
	name: 'ResetPasswordPage',
	data() {
		return {
			email: '',
			loading: false,
			errorMessage: null,
			successMessage: null
		};
	},
	validations: {
		email: { required },
		password: { required }
	},
	computed: {
		emailErrors() {
			const errors = [];
			if(this.$v.email.$dirty) {
				this.$v.email.required || errors.push('Email is required');
			}

			return errors;
		},

		displayedLogoUrl() {
			return this.lightLargeLogoUrl;
		}
	},
	methods: {
		resetPassword() {
			this.errorMessage = null;
			this.successMessage = null;
			this.$v.email.$touch();

			let error = this.$v.email.$error;
			if(!error) {
				if(!email(this.email)) {
					this.errorMessage = 'Only email accounts can reset their password.  You will need to reach out to the admin who provided you with your account to change your password. ';
					return;
				}

				this.loading = true;

				plicAxios.post('/users/recover', {
					user: {
						email: this.email
					},
					plic_app_id: process.env.VUE_APP_PLIC_APP_SLUG
				}).then(() => {
					this.loading = false;
					this.successMessage = 'Reset instructions sent. Check your email.';
				}).catch((error) => {
					this.loading = false;
					this.errorMessage = 'Error resetting password';
					logAxiosError(this.errorMessage, error);
				});
			}
		}
	},
	mounted() {
		this.$refs.email.$el.querySelector('input').focus();

		if(this.$route?.query?.username) {
			this.email = this.$route.query.username;
		}
	}
}
</script>

<style scoped>
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.app-logo {
	width: 50%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2em;
}

.header {
	margin-top: 1em;
	font-weight: normal;
}

.bottom-message {
	margin-top: 1em;
}

.loading {
	min-height: 8em;
}

.policies {
	margin-top: 1em;
	display: block;
	display: flex;
	align-items: center;
	justify-content: center;
}

.policies:deep(a), .forgot-password-button {
	font-size: 16px;
	color: var(--color-zinc-500);
}

.submit-button {
	width: 100%;
}

.card-content {
	padding: 24px;
}

.error-message {
	margin-top: 1em;
	color: var(--v-error-base);
}

.back-button {
	color: var(--color-basic-button-text) !important;
	background-color: var(--color-basic-button-background) !important;
	position: absolute;
	left: 1em;
	top: 1em;
}
</style>