$.DBQueue = function(settings) {
	var ALL_SAVE_ICON_CLASSES = 'red yellow cloud checkmark save notched circle loading';
	var DELAY_SAVE = 500;
	var DELAY_PENDING_SAVE = 500;

	var obj = {};
	
	$.extend(obj, {
		load: function (events) {
			var me = this;
			$.ajax({
				url: this.loadUrl,
				dataType: 'json',
				data: this.postData,
				type: this.loadType,
				success: function (data) {
					if (events.validateResponse && events.validateResponse(data) === false) {
						return;
					}

					me.loadGlobals(data);
					if(me.loadModel) {
						me.loadModel(data);
					}
					if (events.onReady) {
						if(events.onReady.call(obj, data) === false) {
							return;
						}
					}

					if(me.finishLoading) {
						me.finishLoading(data);
					}
				},
				error: function (jqXHR) {
					if (jqXHR.status == 401) {
						$.Alert('Error', 'You are not allowed to view this page', function () {
							window.location = '/login';
						});
					} else if(jqXHR.responseJSON && jqXHR.responseJSON.reason == 'Not allowed') {
						$.Alert('Error', 'You are not allowed to view this page', function () {
							window.location = '/';
						});
					} else {
						me.loadError(events, jqXHR);
					}
				}
			});
		},
		loadGlobals: function(data) {
			if(data.permissions) {
				$.userPermissions = data.permissions;
			} else if(data.userPermissions) {
				$.userPermissions = data.userPermissions;
			} else {
				$.userPermissions = {};
			}
			$.PlicUrl = data.plicUrl;
			$.PlicToken = data.plicToken;
			if(window.setupAxiosAuthToken && data.plicToken) {
				window.setupAxiosAuthToken(data.plicToken);
			}
			$.UniqueId = data.uniqueId;
			$.PlicAccept = data.plicAccept;
			$.PlicOrgId = data.plicOrgId;
			$.UserPlicOrgId = data.userPlicOrgId;
			$.GlobalAlbums = data.albums;
			$.AppName = data.appName;
			$.DisplayAppName = data.displayAppName ?? $.AppName;
			this.plicProjectId = $.PlicProjectId = data.plicProjectId;
			$.PlicBugsnagAPIKey = data.plicBugsnagAPIKey;
			$.isManager = data.isManager;
			$.isCustomer = data.isCustomer;
			$.studioSettingOrgs = data.studioSettingOrgs;
			$.plicSlug = data.plicSlug;

			if (data.masks) {
				$.AvailableMasks = data.masks;

				// Append mask definition to body
				var defs = [];
				for (var i = 0; i < $.AvailableMasks.length; i++) {
					var mask = $.AvailableMasks[i];
					if (mask.definition) {
						// If %ID% is defined, then replace it is a clipPath
						if (mask.definition.indexOf('%ID%') != -1) {
							mask.definition = mask.definition.replace('%ID%', 'Mask-' + mask.id);
							defs.push(mask.definition);
						}
					}
				}

				$.appendToGlobalSVGDefinition(defs);
			}

			if (data.projectName) {
				$.ProjectName = data.projectName;
			}
			$.ProjectShortCode = data.projectShortCode;
			if (data.orgName) {
				$.OrgName = data.orgName;
			}
			$.OrgShortCode = data.orgShortCode;
			if(data.orgId) {
				$.OrgId = data.orgId;
			}
			$.RootOrgId = data.rootOrgId;
			$.UserOrgId = data.userOrgId;
			if (data.projectSettings) {
				$.projectSettings = data.projectSettings;
			}
			if (data.studioSettings) {
				$.studioSettings = data.studioSettings;
			}
			if (data.parentOrgName) {
				$.ParentOrgName = data.parentOrgName;
			}
			if (data.currentUser) {
				if(data.originalUser) {
					$.CurrentUser = data.originalUser;
					$.LoggedInAsUser = data.currentUser;
					$.OriginalUserToken = data.originalUserToken;
				} else {
					$.CurrentUser = data.currentUser;
				}

				$.CurrentUser.userRank = data.rank;
				this.updateKeepAliveData($.CurrentUser);
			}
			$.UserRank = data.rank;
			if (data.userExtras) {
				$.userExtras = data.userExtras;
			}
			if (data.revision) {
				this.revision = data.revision;
			}
			if (data.maxActiveTime) {
				this.maxActiveTime = data.maxActiveTime;
			}
			if(data.isDevEnv) {
				$.IsDevEnv = true;
			}
			if(data.appLicenseUsed) {
				$.AppLicenseUsed = data.appLicenseUsed;
			}

			$.plicLicenseFeatures = {};
			$.LicenseFeatureFlags = data.licenseFeatureFlags;
			if($.LicenseFeatureFlags) {
				for(var name in $.LicenseFeatureFlags) {
					$.plicLicenseFeatures[name] = $.LicenseFeatureFlags[name].enabled;
				}
			}

			if($.GoogleFontList && window.loadGoogleFontsList) {
				var studioFonts = $.getStudioSetting('studioGoogleFonts', '');
				if(studioFonts) {
					studioFonts = studioFonts.split(',');

					const MAX_FONTS = 1_000;
					for(let i = 0; i < studioFonts.length; i += MAX_FONTS) {
						let useFonts = studioFonts.slice(i, i + MAX_FONTS);
						window.loadGoogleFontsList(useFonts);
					}
				}
			}
			if(data.customDictionary && $.FlowLayoutSVGUtils) {
				$.CustomDictionary = data.customDictionary;
				$.merge($.FlowLayoutSVGUtils.ignoreSpellingWords, data.customDictionary.map(function(dictionaryWord) {
					return dictionaryWord.word;
				}));
			}

			if(this.onLoadGlobalData) {
				this.onLoadGlobalData(data);
			}
			if(this.pageSet && this.pageSet.onLoadGlobalData) {
				this.pageSet.onLoadGlobalData(data);
			}
			if(window.onLoadGlobalData) {
				window.onLoadGlobalData(data);
			}
		},
		finishLoading: function() {
			if (this.settings.getSocket && !this.socket) {
				this.socketConnectionTries = 0;
				this.openWebSocket();
			}
		},
		loadError: function (events, jqXHR) {
			if (events.onError && jqXHR && events.onError(jqXHR.responseText)) {
				// Do nothing, handled in events.onError
			} else if(jqXHR && jqXHR.status === 0 && jqXHR.statusText == 'error' && !$.userIsLeavingTab) {
				$.Alert('Network Error', $.DisplayAppName + ' was unable to reach the server correctly. ' + $.DisplayAppName + ' requires PLIC.io to be open in your firewall for operation');
			} else {
				$.Alert('Error', 'Failed to load your project.  Please refresh to try again.  If you continue to see this error please try again later');
			}
		},

		queueChange: function (change, forceOverwrite, options) {
			options = $.extend(true, {
				forcePartialUpdates: false
			}, options);

			var scope = this.changes[change.scope];
			if(!scope) {
				scope = {};
				this.changes[change.scope] = scope;
			}

			if(this.onlyComments && !change.value.comments && change.name !== 'comments' && change.subName !== 'comments') {
				console.warn('stop change: ', change);
				return false;
			}

			var name = change.name;
			if(change.subName) {
				if (!scope[name]) {
					scope[name] = {};
				}
				scope = scope[name];
				name = change.subName;

				if(this.allowPartialUpdates || !change.masterValue) {
					// Only add update if this is not an initial add
					if(!scope[name]) {
						if(change.subMerge) {
							name += '-merge-sub';
						} else {
							name += '-update';
						}
					}
				} else {
					change.value = change.masterValue;
				}
			} else if(options.forcePartialUpdates && !change.masterValue) {
				name += '-update';
			}

			if(scope[name] && !forceOverwrite && typeof change.value == 'object') {
				if($.isArray(scope[name]) && $.isArray(change.value)) {
					$.merge(scope[name], change.value);
				} else if(name && ((name.includes && name.includes('-merge-sub')) || change.subMerge)) {
					$.extend(true, scope[name], change.value);
				} else {
					$.extend(scope[name], change.value);
				}
			} else {
				if(name && name.includes && name.includes('-merge-sub') && scope[name.replace('-merge-sub', '-update')]) {
					let value = change.value;
					// Comments on a page - if we add one comment it is already set to merge, and then we mark another complete it would only save the checked property and not the entire thing
					if(change.masterValue && $.isPlainObject(value)) {
						Object.keys(value).forEach(id => {
							if(change.masterValue[id]) {
								value[id] = change.masterValue[id];
							}
						});
					}


					$.extend(true, scope[name.replace('-merge-sub', '-update')], value);
				} else {
					scope[name] = change.value;
				}

				if(name && name.includes && name.includes('-update') && scope[name.replace('-update', '-merge-sub')]) {
					// We do not need to merge these changes in because -update is already pushing complete object up
					let oldScope = scope[name.replace('-update', '-merge-sub')];
					delete scope[name.replace('-update', '-merge-sub')];

					// If we mark a comment complete and then update add a new comment this would erase the marking of the old comment complete
					if($.isPlainObject(oldScope) && change.masterValue) {
						Object.keys(oldScope).forEach(oldScopeId => {
							if(!scope[name][oldScopeId]) {
								scope[name][oldScopeId] = change.masterValue[oldScopeId];
							}
						});
					}
				}
			}

			if(this.onChangeQueued && !$.isRecursive(5)) {
				this.onChangeQueued(change);
			}

			if(this.saving) {
				this.pendingSave = true;
			} else if(this.autoSave) {
				this.startDelayedSave(this.delaySaveTime);
			}

			return true;
		},
		queueChangeFromEvent: function(event) {
			var change = {};
			var originalObject;
			var context = $.merge([], event.context);
			var realContextLength = context.length;
			switch(context[0]) {
				case 'page':
					change.scope = 'pages';
					change.name = context[1];

					originalObject = this.pageSet.getPageById(context[1]);
					if(originalObject) {
						change.scope = originalObject.changeScope;
					}
					realContextLength--;
					break;
				case 'pageSet':
					change.scope = 'yearbook';
					change.name = context[1];

					originalObject = this.pageSet;
					break;
				case 'subjects':
					// Currently all subject changes are PLIC changes
					this.plicAPIChangeFromEvent(event);
					this.queueEvent(event);
					return;
				case 'batches':
					originalObject = this.classes.getMatch({
						id: event.context[1]
					}, 'id');
					break;
				case 'customDictionary':
					if(event.action === 'remove') {
						$.ajax({
							url: 'ajax/deleteDictionaryWord.php',
							data: {
								word: event.args[0].word,
								orgId: window.getDictionaryOrgId()
							},
							type: 'POST'
						});
					} else if(event.action === 'insert') {
						$.ajax({
							url: 'ajax/saveDictionaryWord.php',
							data: {
								word: event.args[0].word,
								orgId: window.getDictionaryOrgId()
							},
							type: 'POST'
						});
					}
					break;
				default:
					console.error('Failed to queue change from event due to unknown context');
					return;
			}

			var extraContext = null, arrayOfProperties = null;
			if(realContextLength == 3 && context[context.length - 1] == 'css') {
				extraContext = context[context.length - 1];
				context.pop();
			} else if(realContextLength == 2 && context[2] == 'extraClasses') {
				arrayOfProperties = 'id';
			} else if(realContextLength == 2 && context[2] == 'classObj') {
				extraContext = 'id';
			}

			var saveDBChange = true;
			var forceOverwrite = false;
			var value;
			if(context[0] === 'customDictionary') {
				saveDBChange = false;
			} else if(context.length == 4 && context[2] == 'classObj' && context[3] == 'subjects') {
				change.scope = 'batches';
				change.name = originalObject.classObj.id;
				change.value = originalObject.classObj.subjects.arrayOfProperties('id');
			} else if(context.length === 3 && context[0] === 'batches' && context[2] === 'subjects') {
				change.scope = 'batches';
				change.name = originalObject.id;
				if(event.action === 'insert' || event.action === 'remove') {
					$.ajax({
						url: 'ajax/saveBatch.php',
						dataType: 'json',
						data: {
							batches: JSON.stringify([
								{
									batchId: originalObject.id,
									subjects: $.SubjectManagement.getAppSpecificSubjectsData(originalObject.subjects)
								}
							])
						},
						type: 'POST',
						error: function () {
							$.Alert('Error', 'Failed to ' + event.action + 'into batch');
						}
					});

					saveDBChange = false;
				} else {
					change.value = originalObject.subjects.arrayOfProperties('id');
				}
			} else if(context.length > 3) {
				change.subName = context[2];
				change.masterValue = originalObject[context[2]];

				change.value = {};
				value = originalObject[context[2]][context[3]];
				if(value === undefined) {
					value = null;
				}

				change.value[context[3]] = value;
			} else if(context.length == 3) {
				change.value = {};

				value = originalObject[context[2]];
				if(extraContext && value) {
					value = value[extraContext];
				}
				if(arrayOfProperties) {
					value = value.arrayOfProperties(arrayOfProperties);
				}

				if(typeof value == 'undefined') {
					value = null;
				}
				change.value[context[2]] = value;
			} else if(event.action == 'remove') {
				change.value = {
					remove: 'true'
				}
			} else if(event.action == 'insert') {
				var page = originalObject;
				change.value = this.addEntirePage(page);
				
				if(saveDBChange && $.isArray(event.args[0])) {
					for(var i = 1; i < event.args[0].length; i++) {
						var pageData = event.args[0][i];
						page = this.pageSet.getPageById(pageData.id);

						if(page) {
							var copyChange = $.extend(true, {}, change);
							copyChange.name = page.id;
							copyChange.value = this.addEntirePage(page);

							this.queueChange(copyChange);
						}
					}
				}
			} else if(event.action == 'update' && context[0] == 'page' && context.length == 2) {
				change.value = this.addEntirePage(originalObject, false, {
					stripPageNumber: true
				});
			} else if(event.action == 'moveBefore' && context[0] == 'page' && context.length == 2) {
				this.pageSet.queueMovePageTo(originalObject, -1);
				saveDBChange = false;
			} else {
				value = originalObject[context[1]];
				if(extraContext) {
					value = value[extraContext];
				}
				if(typeof value == 'undefined') {
					value = null;
				}

				change.value = value;
			}

			if(saveDBChange) {
				this.queueChange(change, forceOverwrite);
			}
			this.queueEvent(event);
		},
		plicAPIChangeFromEvent: function(event) {
			if(event.context[0] == 'subjects') {
				if(event.context.length == 4 && event.context[3] == 'yearbookCrop') {
					var subject = this.pageSet.subjects.getMatch({}, 'id', event.context[1]);
					if (!event.args[1]) {
						$.plicAPI({
							method: 'photo-crops/' + event.args[0].id,
							type: 'DELETE',
							error: function() {
								$.Alert('Error', 'Failed to save crop information');
							}
						});
					} else {
						var crop = subject.yearbookPhoto.yearbookCrop;
						$.plicAPI({
							method: 'photo-crops/' + crop.id,
							params: {
								photo_crop: {
									name: this.cropName,
									x: crop.x,
									y: crop.y,
									width: crop.width,
									height: crop.height
								}
							},
							type: 'PUT',
							error: function() {
								$.Alert('Error', 'Failed to save crop information');
							}
						});
					}
				} else if(event.context.length === 2) {
					if(!this.pageSet || !this.pageSet.getSubjectTemplate || !this.pageSet.getSubjectTemplate()) {
						console.error('Cannot undo event due to missing template: ', event);
						return;
					}

					var properties = event.args[1];
					var idMap = this.pageSet.getTemplateIdMap();
					var plicProperties = {};
					var otherParams = {};
					for(var propName in properties) {
						if(propName === 'photo') {
							otherParams.primary_subject_photo_id = properties[propName];

							if($.primarySubjectPoseFlag) {
								otherParams.pose_flags = {};
								otherParams.pose_flags[$.primarySubjectPoseFlag] = properties[propName];
							}

							continue;
						} else if(['yearbookPhoto', 'photoCdnUrl'].indexOf(propName) !== -1) {
							continue;
						}

						var id = idMap[propName];
						plicProperties[id] = properties[propName];
					}

					$.plicAPI({
						method: 'subjects/' + event.context[1],
						params: {
							subject:  $.extend({
								properties: plicProperties,
								merge_properties: true
							}, otherParams)
						},
						type: 'PUT',
						error: function() {
							$.Alert('Error', 'Failed to save name changes');
						}
					});
				}
			} else {
				console.error('Unknown plic API change from event: ', event);
			}
		},
		queueEvent: function (event) {
			var events = this.changes['events'];
			if (!events) {
				events = this.changes['events'] = [];
			}

			events.push(event);

			if (this.saving) {
				this.pendingSave = true;
			} else {
				this.startDelayedSave(this.delaySaveTime);
			}
		},
		updateUserSelection: function(id, selection) {
			// Funky logic to get around order of operations: Obj A gain focus, Obj B lose focus
			// Want to make sure we only update selection if we are setting it TO something, or if we are clearing it and current id matches what is losing focus
			var oldSelection = this.keepAliveData.currentSelection;
			var oldId = null;
			if(oldSelection) {
				if($.isArray(oldSelection)) {
					if(oldSelection.length) {
						oldId = oldSelection[0].id;
					}
				} else {
					oldId = oldSelection.id;
				}
			}
			if(!oldSelection || selection || oldId == id) {
				this.updateKeepAliveData({
					currentSelection: selection
				}, true);
			}
		},
		updateKeepAliveData: function(newData, sendUpdate) {
			$.extend(this.keepAliveData, newData);
			this.keepAliveUpdated = true;

			if(sendUpdate) {
				if(this.saving) {
					// TODO: What to do if already saving
				} else {
					this.startDelayedSave(this.delaySaveTime, true)
				}
			}
		},
		startDelayedSave: function (delayTime, socketChange) {
			var newQueue = true;
			if (this.currentSave) {
				clearTimeout(this.currentSave);
				newQueue = false;
			}

			// Check that changes weren't remove in onChangeQueued
			var saveChanges = $.getObjectCount(this.changes) > 0;
			if (saveChanges || socketChange) {
				this.currentSave = setTimeout(() => {
					// Save grouped events for later save when we are done with entire change
					// This should be auto retrying until it can send the full changes - even if we are broken and not calling stopGroupedEvents after 5 seconds it should auto send
					if(this.userEvents?.isGroupedEvents()) {
						this.startDelayedSave(DELAY_PENDING_SAVE);
					} else {
						this.saveChanges();
						this.currentSave = null;
					}
				}, delayTime);

				if (newQueue && saveChanges) {
					if (this.onPendingSaves) {
						this.onPendingSaves();
					}

					if (!this.saving) {
						this.setProgressIndicator('pending');
					}
				}
			}
		},
		pushChanges: function () {
			// Already saving
			if (this.saving) {
				if(this.pendingSave) {
					this.pendingSaveImmediate = true;
				}
				return;
			}

			if (this.currentSave) {
				clearTimeout(this.currentSave);
				this.currentSave = null;
			}

			this.saveChanges();
		},
		removeChange: function (change) {
			if (this.changes[change.scope] && this.changes[change.scope][change.name]) {
				delete this.changes[change.scope][change.name];
			}
		},
		clearChanges: function() {
			this.changes = {};
		},
		saveChanges: function (options) {
			if (!options) {
				options = {};
			}
			if (!this.enabled) {
				return;
			}

			// Already saving, wait until after current changes are saved before sending new set
			if (this.saving) {
				return;
			}

			var me = this;
			this.saving = true;
			this.pendingSave = false;

			// Strip functions so ajax doesn't try to execute them
			var changes = $.removeFunctions(this.changes, [this, this.pageSet]);
			// Immediately clear out pending changes to get rid of timing issues
			this.currentSavingChanges = this.changes;
			this.changes = {};

			var events = changes.events;
			changes = JSON.stringify(changes);
			// Don't send out a null save
			if (changes == '{}' || !changes) {
				this.saving = false;

				if (me.onSavesComplete) {
					me.onSavesComplete();
				}
				if (options.onSavesComplete) {
					options.onSavesComplete();
				}

				if(this.keepAliveUpdated) {
					this.sendSocketMessage({});
				}

				return;
			}

			if ($.getGETParams().debugSave) {
				this.saving = false;
				var parsedChanges = JSON.parse(changes);
				if(this.compressSaves && window.btoa && window.pako) {
					parsedChanges.uncompressedChanges = changes.length;
					parsedChanges.compressedChanges = window.btoa(window.pako.deflate(changes, {to: 'string'})).length;
				}
				// eslint-disable-next-line
				console.log(parsedChanges);

				if ($.getGETParams().debugOnlyEvents) {
					me.sendSocketMessage({
						events: events
					});
				}

				this.setProgressIndicator('start');
				return;
			}

			if (this.onStartSaves) {
				this.onStartSaves();
			}
			if (options.onStartSaves) {
				options.onStartSaves();
			}
			this.setProgressIndicator('saving');

			var postData = $.extend(true, {}, this.postData);
			if(this.compressSaves && window.btoa && window.pako) {
				// This is only necessary for users with crap upload speeds
				postData.changes = window.btoa(window.pako.deflate(changes, {to: 'string'}));
			} else {
				postData.changes = changes;
			}
			postData.onlyComments = this.onlyComments;
			if (this.revision) {
				postData.revision = this.revision;
			}

			try {
				this.currentAjax = $.proxyAjax({
					url: this.saveUrl,
					dataType: 'json',
					data: postData,
					type: 'POST',
					timeout: 15000,
					success: function (data) {
						me.currentAjax = null;
						me.currentAjaxAborted = false;
						me.saving = false;
						me.currentSavingChanges = null;
						if(!data || data.status == 'error') {
							$.Alert('Error', 'Failed to save some changes');
						} else if (me.onSave) {
							me.onSave();
						}

						if (me.pendingSave) {
							me.pendingSave = false;
							me.saving = false;

							if (me.pendingSaveImmediate) {
								me.pendingSaveImmediate = false;
								me.saveChanges();
							} else {
								me.startDelayedSave(DELAY_PENDING_SAVE);
							}
						} else {
							if (me.onSavesComplete) {
								me.onSavesComplete();
							}
							if (options.onSavesComplete) {
								options.onSavesComplete();
							}
							me.setProgressIndicator('start');
						}

						if (data.revision) {
							me.revision = data.revision;
						}

						if (events && me.socket) {
							me.sendSocketMessage({
								events: events
							});
						}
					},
					error: function (jqXHR) {
						me.currentAjax = null;
						me.saving = false;

						if (me.currentAjaxAborted) {
							me.currentAjaxAborted = false;
							return;
						} else {
							if (me.hardErrorStatusCodes.indexOf(jqXHR.status) == -1 && me.currentSavingChanges) {
								var events = [];
								if (me.currentSavingChanges.events) {
									$.merge(events, me.currentSavingChanges.events);
								}
								if (me.changes.events) {
									$.merge(events, me.changes.events);
								}

								me.changes = $.extend(true, {}, me.currentSavingChanges, me.changes);
								// If we are putting an -update back on the queue, we need to merge a -sub-merge with it properly
								me.recursivelyCheckForConflictingMerges(me.changes);
								if(events.length) {
									me.changes.events = events;
								}
							}
							me.handleSaveError(options);
						}

						var errorMessage = 'Failed to save some changes';
						if(jqXHR.responseJSON && jqXHR.responseJSON.reason && jqXHR.responseJSON.reason.indexOf('Failed to update deleted page with fields') !== -1) {
							errorMessage = 'Failed to save some changes due to a page being deleted by another user.  If you continue to get this error, try refreshing the page. If the cloud checkmark in the top right of the screen is yellow, please check that your network firewall is allowing socket connections so you get changes from other users.'
						}

						$.Alert('Error', errorMessage);
						if(options.onSavesError) {
							options.onSavesError();
						}
					}
				});
			} catch(exception) {
				this.handleSaveError(options);

				$.fireErrorReport('Failed to save some changes', 'Failed to fire save ajax', 'Failed to fire save ajax', {
					exception: exception,
					postData: postData
				});
			}
		},
		recursivelyCheckForConflictingMerges(changes) {
			if(!$.isPlainObject(changes)) {
				return;
			}

			Object.keys(changes).forEach(key => {
				if(key.endsWith('-update')) {
					let subKey = key.replace('-update', '-merge-sub');
					if(changes[subKey]) {
						$.extend(true, changes[key], changes[subKey]);
						delete changes[subKey];
					}
				} else if($.isPlainObject(changes[key])) {
					this.recursivelyCheckForConflictingMerges(changes[key]);
				}
			});
		},
		handleSaveError: function(options) {
			this.currentAjax = null;
			this.saving = false;
			this.currentSavingChanges = null;

			if (this.onSaveError) {
				this.onSaveError();
			}
			if (options.onSaveError) {
				options.onSaveError();
			}

			if (this.pendingSave) {
				this.pendingSave = false;
				if (this.pendingSaveImmediate) {
					this.pendingSaveImmediate = false;
					this.saveChanges();
				} else {
					this.startDelayedSave(DELAY_PENDING_SAVE);
					$(this.progressIndicatorOuter).addClass('red');
				}
			} else {
				this.setProgressIndicator('error');
			}
		},
		enableSaving: function () {
			this.enabled = true;

			if (this.onEnabled) {
				this.onEnabled();
			}

			if(this.userEvents) {
				this.userEvents.registerForGlobalUndo();
			}
		},
		disableSaving: function () {
			this.enabled = false;
			this.pendingSave = false;
			if (this.currentAjax) {
				this.currentAjaxAborted = true;
				this.currentAjax.abort();
				this.currentAjax = null;
			}

			if (this.onDisabled) {
				this.onDisabled();
			}
			if(this.userEvents) {
				this.userEvents.unregisterForGlobalUndo();
			}

			if (this.progressIndicator) {
				$(this.progressIndicator).hide();
			}
		},
		setOnlyCommentsAllowed: function(onlyComments) {
			this.onlyComments = onlyComments;

			if(onlyComments) {
				if(this.userEvents) {
					this.userEvents.unregisterForGlobalUndo();
				}
			}
		},
		setProgressIndicator: function (status) {
			this.currentSavingStatus = status;
			if (this.progressIndicator) {
				this.updateProgressIndicator();
			}

			if(this.onSaveStatusUpdated) {
				this.onSaveStatusUpdated(status);
			}
		},
		updateProgressIndicator: function() {
			var status = this.currentSavingStatus;

			$(this.progressIndicator).css('cursor', 'default').attr('data-position', 'left center');
			if (status == 'start') {
				var tooltip = 'All progress saved';
				if(this.currentSocketStatus == 'warning') {
					tooltip += ' (Cannot connect to server.  Try ' + window.location.host + '/test if this continues)';
				}

				$(this.progressIndicator).attr('data-tooltip', tooltip);
				$(this.progressIndicatorOuter).removeClass(ALL_SAVE_ICON_CLASSES).addClass('cloud');

				if(this.currentSocketStatus == 'warning') {
					$(this.progressIndicatorOuter).addClass('yellow');
				}

				$(this.progressIndicatorInner).addClass('checkmark');
			} else {
				$(this.progressIndicatorInner).removeClass('checkmark');
				if (status == 'saving') {
					$(this.progressIndicator).attr('data-tooltip', 'Saving');
					$(this.progressIndicatorOuter).removeClass(ALL_SAVE_ICON_CLASSES).addClass('notched circle loading');
				} else if (status == 'pending') {
					$(this.progressIndicator).attr('data-tooltip', 'Pending saves');
					$(this.progressIndicatorOuter).removeClass(ALL_SAVE_ICON_CLASSES).addClass('save');
					$(this.progressIndicator).css('cursor', 'pointer');
				} else if (status == 'error') {
					$(this.progressIndicator).attr('data-tooltip', 'Error saving');
					if($.getObjectCount(this.changes) > 0) {
						$(this.progressIndicatorOuter).removeClass(ALL_SAVE_ICON_CLASSES).addClass('red save');
						$(this.progressIndicator).css('cursor', 'pointer');
					} else {
						$(this.progressIndicatorOuter).removeClass(ALL_SAVE_ICON_CLASSES).addClass('red cloud');
						$(this.progressIndicatorInner).addClass('checkmark');
					}
				}
			}
		},
		setProgressIndicatorSocketStatus: function(status) {
			if(this.currentSocketStatus === status) {
				return;
			}

			this.currentSocketStatus = status;
			this.updateProgressIndicator();

			if(this.onSaveStatusUpdated) {
				this.onSaveStatusUpdated(status);
			}
		},
		openWebSocket: function () {
			var me = this;

			var socketProtocol = 'wss';
			if(window.location.protocol == 'http:') {
				socketProtocol = 'ws';
			}

			this.socket = new WebSocket(socketProtocol + '://' + window.location.host + '/' + this.settings.getSocket());
			this.socket.onopen = function() {
				me.setProgressIndicatorSocketStatus('open');
				me.socketConnectionTries = 0;
				me.sendSocketMessage({});
			};
			this.socket.onclose = function (e) {
				me.socketConnectionTries++;
				me.stopSocketKeepAliveTimer();

				if($.userIsLeavingTab) {
					console.warn('Closing socket due to user leaving');
				} else {
					me.setProgressIndicatorSocketStatus('warning');
					console.warn('Socket connection lost (retrying in ' + (me.socketConnectionTries * me.socketConnectionTries) + ' seconds)', e);

					window.setTimeout(function () {
						me.openWebSocket();
					}, me.socketConnectionTries * me.socketConnectionTries * 1000);
				}
			};
			this.socket.onerror = function(e) {
				if($.userIsLeavingTab) {
					console.warn('Closing socket due to user leaving');
				} else {
					me.setProgressIndicatorSocketStatus('warning');
					console.error('Failed to send message to socket', e);
					me.startSocketKeepAliveTimer(me.errorKeepAliveTime);
				}
			};

			this.socket.onmessage = function (e) {
				try {
					var json = JSON.parse(e.data);
					if (me.onSocketMessage) {
						try {
							me.onSocketMessage(json);
						} catch(error) {
							console.error('Failed to handle socket message', e, error);
						}
					}
				} catch (error) {
					$.fireErrorReport(null, 'Failed socket handler', 'Failed to handle socket message', {
						socketMessage: e,
						error: error
					});
				}
			};
		},
		refreshWebSocket: function() {
			if(!this.socket) {
				return;
			}

			this.socket.close();
		},
		closeWebSocket: function() {
			if(!this.socket) {
				return;
			}

			this.stopSocketKeepAliveTimer();
			this.socket.onclose = null;
			this.socket.close();
			this.socket = null;
		},
		sendSocketMessage: function(msg) {
			$.extend(msg, {
				keepAlive: this.keepAliveData
			});

			if(this.socket) {
				if(this.socket.readyState == WebSocket.OPEN) {
					try {
						if(this.queuedEvents.length) {
							msg.events = $.merge($.merge([], this.queuedEvents), msg.events || []);
							this.queuedEvents = [];
						}
						this.socket.send(JSON.stringify(msg));
					} catch (e) {
						// https://bugzilla.mozilla.org/show_bug.cgi?id=732363
						console.error('Firefox sometimes throws error even though connected');

						if($.globalBugsnagInfo) {
							$.globalBugsnagInfo['lastError'] = {
								type: 'websocket send error',
								message: e.message
							};
						}
					}
					this.keepAliveUpdated = false;
					this.startSocketKeepAliveTimer();
				} else {
					console.warn('Attempting to send message in readyState: ' + (this.socket ? this.socket.readyState : null));
					if(this.socket.readyState == WebSocket.CONNECTING) {
						this.startSocketKeepAliveTimer(this.errorKeepAliveTime);
					}

					if(msg.events) {
						$.merge(this.queuedEvents, msg.events);
					}
				}
			} else {
				if($.getObjectCount(msg) === 1) {
					console.warn('trying to send keep alive data before socket is open, ignore')
				} else {
					$.fireErrorReport(null, 'Trying to send message with no socket', 'Trying to send message with no socket');
				}
			}
		},
		startSocketKeepAliveTimer: function (keepAliveTime) {
			if(!keepAliveTime) {
				keepAliveTime = this.socketKeepAliveTime;
			}

			var me = this;
			this.stopSocketKeepAliveTimer();
			this.socketKeepAlive = window.setTimeout(function () {
				if(me.socketKeepAlive) {
					me.socketKeepAlive = null;
					me.sendSocketMessage({});
				}
			}, keepAliveTime);
		},
		stopSocketKeepAliveTimer: function () {
			if (this.socketKeepAlive) {
				window.clearTimeout(this.socketKeepAlive);
				this.socketKeepAlive = null;
			}
		},
		unregister: function() {
			if(this.onVisibilityChangeHandler) {
				document.removeEventListener('visibilitychange', this.onVisibilityChangeHandler);
				this.onVisibilityChangeHandler = null;
			}
		},

		changes: {},
		saving: false,
		enabled: true,
		settings: settings,
		keepAliveData: {},
		hardErrorStatusCodes: [400, 401, 404],
		currentSavingStatus: 'start',

		postData: {},
		onlyComments: false,
		currentAjax: null,
		autoSave: true,
		delaySaveTime: DELAY_SAVE,
		socketKeepAliveTime: 25000,
		errorKeepAliveTime: 3000,
		allowPartialUpdates: true,
		loadType: 'POST',
		compressSaves: false,
		queuedEvents: []
	}, settings);

	if(obj.progressIndicator) {
		obj.progressIndicatorOuter = $('<i class="big icon">');
		obj.progressIndicatorInner = $('<i class="inverted icon">');

		$(obj.progressIndicator).append(obj.progressIndicatorOuter).append(obj.progressIndicatorInner).addClass('icons progressIndicator').click(function() {
			if($(obj.progressIndicatorOuter).hasClass('save')) {
				obj.pushChanges();
			} else if($(obj.progressIndicatorInner).hasClass('checkmark')) {
				obj.setProgressIndicator('saving');
				window.setTimeout(function() {
					if($(obj.progressIndicatorOuter).hasClass('circle loading')) {
						obj.setProgressIndicator('start');
					}
				}, 500);
			}
		}).show();

		obj.setProgressIndicator('start');
	}
	
	
	const existingOnUnloadHandler = window.onbeforeunload;
	window.onbeforeunload = function() {
		var changed = false;
		if(obj.enabled) {
			if (obj.currentSave) {
				if($.getObjectCount(obj.changes) > 0) {
					clearTimeout(obj.currentSave);
					obj.currentSave = null;
					obj.saveChanges();
					changed = true;
				}
			} else if (obj.saving) {
				changed = true;

				if(obj.pendingSave) {
					obj.pendingSaveImmediate = true;
				}
			}
		}

		if(existingOnUnloadHandler) {
			const returnFromExisting = existingOnUnloadHandler();
			if(returnFromExisting != undefined) {
				return returnFromExisting;
			}
		}

		if(changed && !$.getGETParams().debugSave) {
			return 'There are unsaved changes.  If you leave right now, you will lose your changes';
		} else if($.downloadButtonLastClicked && (new Date().getTime() - $.downloadButtonLastClicked) < 500) {
			return;
		} else {
			$.userIsLeavingTab = true;
			return;
		}
	};
	document.addEventListener('visibilitychange', obj.onVisibilityChangeHandler = function() {
		if(document.visibilityState === 'visible') {
			obj.sendSocketMessage({});
		}
	});
	
	return obj;
};